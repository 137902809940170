import { Button, Dialog, DialogActions, DialogContent, ImageList, ImageListItem } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../context/AuthContext'

export const ModalImagenesEvidencia = ({apoyoSeleccionado}) => {
    const {modalImagenesEvidencia, setModalImagenesEvidencia} = useContext(AuthContext)

    const handleClose = () => {
        setModalImagenesEvidencia(false)
    }

    useEffect(() => {
        console.log('apoyo seleccionado', apoyoSeleccionado);
    }, [apoyoSeleccionado])
    

  return (
    <Dialog
        size="xl"
        fullWidth
        open={modalImagenesEvidencia}
        onClose={handleClose}
    >
        <DialogContent>
        <ImageList sx={{ width: 500, height: 450 }} cols={1} rowHeight={264}>
      {apoyoSeleccionado?.evidencia.map((item) => (
        <ImageListItem key={item.img}>
          <img
            srcSet={`${item}?w=264&h=264&fit=crop&auto=format&dpr=2 2x`}
            src={`${item}?w=264&h=264&fit=crop&auto=format`}
         //   alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => {setModalImagenesEvidencia(false)}}
                style={{
                    textTransform:'unset'
                }}
            >
                Cerrar
            </Button>
        </DialogActions>
    </Dialog>
  )
}
