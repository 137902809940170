import React from 'react'
import { ArrowBack, ChevronLeft, ChevronRight, Search } from '@mui/icons-material';
import { Autocomplete, Button, IconButton, TextField, Typography } from '@mui/material';
import { useContext, useEffect } from 'react'
import { useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { TablaUsuarios } from '../components/usuarios/TablaUsuarios';
import { CardUsuario } from '../components/usuarios/CardUsuario';
import { ObtenerDatos } from '../helpers/ApiHelper';
import { ModalUsuario } from '../components/usuarios/ModalUsuario';
import { ModalPermisos } from '../components/usuarios/ModalPermisos';
import { ModalPassword } from '../components/usuarios/ModalPassword';


export const UsuariosPage = () => {
  const {modalUsuario, setModalUsuario, modalPermisos, setModalPermisos} = useContext(AuthContext)
  const [usuarioBuscar, setUsuarioBuscar] = useState()
  const [teclaPresionada, setTeclaPresionada] = useState()
  const [pagina, setPagina] = useState()
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState()
  const [registros, setRegistros] = useState()
  const [usuarios, setUsuarios] = useState();
  const [permisos, setPermisos] = useState()


  const obtenerUsuarios = () => {
    ObtenerDatos('/usuarios.php?funcion=usuarios').then(response => {
      console.log('usuarios obtenidos', response)
      setUsuarios(response)
    })
  }

  useEffect(() => {
    obtenerPermisos()
  }, [usuarioSeleccionado])
  

  const paginaAnterior = () => {
    if(pagina-1>0) {
        setPagina(pagina-1);
    }
}

const paginaSiguiente = () => {
    if((pagina+1)<=Math.ceil(registros/100)) {
        setPagina(pagina+1);
    }
}
useEffect(() => {
  obtenerUsuarios()
}, [])

useEffect(() => {
    if(!modalUsuario) obtenerUsuarios();
}, [modalUsuario])

const editarUsuario = async() => {
    await setModalUsuario(true)
}

const agregarUsuario = async() => {
    await setUsuarioSeleccionado();
    await setModalUsuario(true);

}

const obtenerPermisos = () => {
  const data = new FormData();
  data.append('idUsuario', usuarioSeleccionado?.Usuarios_IdUsuario || 0)
  fetch(process.env.REACT_APP_API_URL+'/usuarios.php?funcion=permisos', {
      method:'POST',
      body:data
  })
  .then(response => response.json())
  .then(permisos => {
      console.log(permisos)
      setPermisos(permisos)
    //console.log(reportes.length)
  })
  .catch(error => {
    console.log('Ocurrio un error', error)
  })

}


  return (
    <>
    <div
    style={{
        marginLeft:20,
        marginTop:20,
        marginRight:20,
        display:'flex',
        flexDirection:'row',
        flex:5,
        justifyContent:'space-between',
        alignItems:'center'
    }}
    > 
    <Typography
        style={{
        fontSize:20,
        fontWeight:600
        }}
    >
        Usuarios
    </Typography>
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            backgroundColor:'transparent'
        }}
    >
        <TextField 
            onChange={(e) => {setUsuarioBuscar(e.target.value)}}
            onKeyUp={(e) => {teclaPresionada(e)}}
            size='small'
            variant='outlined'
            label='Usuario'
        />
        <IconButton
            onClick={() => {setPagina(1); obtenerUsuarios()}}
        >
            <Search />
        </IconButton>                
    </div>
    <Button
        onClick={() => {agregarUsuario()}}
        variant="contained"
        style={{backgroundColor:'#B3282D', borderRadius:15, textTransform:'unset'}}
    >
        Agregar
    </Button>
    </div>
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            flex:95,
            backgroundColor:'transparent',
            marginBottom:10,
            marginLeft:10,
            marginTop:10
        }}
        >
            <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:80,
                    backgroundColor:'transparent'
                }}
            >
                <TablaUsuarios usuarios={usuarios} usuarioSeleccionado={usuarioSeleccionado} setUsuarioSeleccionado={setUsuarioSeleccionado} setModalUsuario={setModalUsuario} modalUsuario={modalUsuario} obtenerPermisos={obtenerPermisos} />
                <div
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'flex-end',
                        marginRight:40,
                        alignItems:'center'
                    }}
                >Página {pagina} de {Math.ceil(registros/100)}
                    <IconButton
                        onClick={() => paginaAnterior()}
                    >
                        <ChevronLeft />
                    </IconButton>
                    <IconButton
                        onClick={() => paginaSiguiente()}
                    >
                        <ChevronRight />
                    </IconButton>
                </div>
            </div>
            <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:15,
                    backgroundColor:'transparent',
                    marginLeft:20,
                    marginRight:20
                }}
            >
                <CardUsuario usuarioSeleccionado={usuarioSeleccionado} obtenerUsuarios={obtenerUsuarios} />
            </div>
    </div>
    <ModalUsuario modalUsuario={modalUsuario} setModalUsuario={setModalUsuario} usuarioSeleccionado={usuarioSeleccionado}/>
    <ModalPermisos modalPermisos={modalPermisos} setModalPermisos={setModalPermisos} usuarioSeleccionado={usuarioSeleccionado} obtenerPermisos={obtenerPermisos} permisos={permisos} />
    <ModalPassword usuarioSeleccionado={usuarioSeleccionado} />
</>
  )
}
