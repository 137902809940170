import React, { useEffect, useState } from 'react'
import { ObtenerDependencias } from '../helpers/DependenciasHelper'
import { Autocomplete, TextField } from '@mui/material'
import Select from 'react-select';

const useDependencias = () => {
    const [dependencias, setDependencias] = useState();
    const [dependenciaSeleccionada, setDependenciaSeleccionada] = useState();

    const obtenerDependencias = () => {
        ObtenerDependencias().then(response => {
            let opciones = new Array();
            opciones.push({value:'%', label:'Todas'})
            response?.map(dependencia => {
                opciones.push({value:dependencia.idDependencia,label:dependencia.dependencia})
            })

            setDependencias(opciones)
            console.log('dependencias en componente',response)
        });
    }

    useEffect(() => {
        obtenerDependencias();
    }, [])
    
    const ComboDependencias = () => {
        return (
            <div>
                <Select
                    onChange={(e) => {setDependenciaSeleccionada(e)}}
                    value={dependenciaSeleccionada}
                    options={dependencias}
                    placeholder="Dependencia"
                    menuPortalTarget={document.body} 
                    InputLabelProps={{
                        style:{
                            fontSize:11
                        }
                    }}
                    InputProps={{
                        style:{
                            fontSize:11
                        }
                    }}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999, fontSize:11 }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? 'grey' : 'green',
                            fontSize:12,
                            minWidth:150,
                            maxHeight:20,
                            borderRadius:10
                        }),
                    }}
                    style={{
                        width:150,
                        maxHeight:20,
                        menuPortal: base => ({ ...base, zIndex: 999999, fontSize:11 }),
        
                    }}
                />
    </div>
  )
    }
  return {dependencias, ComboDependencias, dependenciaSeleccionada, setDependenciaSeleccionada}
};

export default useDependencias

