import React, { useEffect, useRef, useState } from 'react'
import logoEncabezado from '../assets/logoEncabezado.png'
import imagenDashboard from '../assets/imagenDashboard.png'
import graficaOnda from '../assets/graficaOnda.png'
import regalo from '../assets/regalo.png'
import sector from '../assets/sector.png'
import { UsuarioComponent } from '../components/UsuarioComponent'
import useDependencias from '../components/DependenciasComponent'
import useTiposApoyo from '../hooks/useTiposApoyo'
import { Button, TextField, Typography } from '@mui/material'
import { ObtenerDatos } from '../helpers/ApiHelper'
import dayjs from 'dayjs'
import { BadgeOutlined, Group, Groups, PermIdentity, Redeem, SentimentDissatisfied, SentimentDissatisfiedOutlined, SentimentSatisfiedAlt, SentimentVeryDissatisfiedOutlined, SentimentVerySatisfiedOutlined } from '@mui/icons-material'
import { usePDF } from 'react-to-pdf';

export const DashboardPage = () => {
  const {dependencias, ComboDependencias, dependenciaSeleccionada} = useDependencias();
  const {tipoApoyoSeleccionado, ComboTiposApoyo, obtenerTiposApoyo} = useTiposApoyo();
  const [inicio, setInicio] = useState(dayjs().startOf('month').format('YYYY-MM-DD'))
  const [fin, setFin] = useState(dayjs().format('YYYY-MM-DD'))
  const [datos, setDatos] = useState();
  const refDashboard = useRef();

  useEffect(() => {
    console.log('selecciono dependencia', dependenciaSeleccionada)
    obtenerTiposApoyo(dependenciaSeleccionada?.value || 0);
  }, [dependenciaSeleccionada])
  

  const obtenerDatos = () => {
    const data = new FormData();
    console.log(inicio,fin,dependenciaSeleccionada)
    data.append('inicio', inicio);
    data.append('fin', fin);
    data.append('idDependencia', dependenciaSeleccionada?.value || '%')
    data.append('idTipoApoyo', tipoApoyoSeleccionado?.value || '%')
    ObtenerDatos('/dashboard.php?funcion=datosDashboard',data).then(response => {
      console.log('datos para el dashboard', response)
      setDatos(response)
    })
  }

  useEffect(() => {
    console.log('datos actualizados', datos)
  }, [datos])

  useEffect(() => {
    console.log('actualizando datos')
    obtenerDatos()
  }, [])
  
  const { toPDF, targetRef } = usePDF({
    filename: 'dashboard.pdf',
    page: {
      format: 'Letter',
      orientation: 'landscape',
      margin: 20,
    },
  });


  return (
    <>
    {/* <button onClick={() => toPDF()}>Download PDF</button> */}
    <div
      ref={targetRef}
      style={{
        display:'flex',
        flex:1,
        flexDirection:'column',
        marginLeft:20
      }}
    >
      {/* <div
        style={{
          display:'flex',
          flexDirection:'row',
          flex:10,
          justifyContent:'flex-end',
          marginTop:20,
          marginRight:20
        }}
      >
        <img
            style={{
                width:200,
                height:40,
                
//                marginTop:'15%'
            }}
            src={logoEncabezado}
        />
        <UsuarioComponent /> 
      </div> */}
      <div
      style={{
          display:'flex',
          flexDirection:'row',
          flex:45,
          justifyContent:'flex-start',
          marginTop:20,
          marginRight:20,
        }}
      >
        <div
          style={{
            display:'flex',
            flexDirection:'column',
            flex:55,
            backgroundColor:'white',
            borderRadius:20
          }}
        >
          <div
            style={{
              backgroundColor:'#B3282D',
              display:'flex',
              flexDirection:'row',
              flex:20,
              borderTopLeftRadius:15,
              borderTopRightRadius:15,
              alignItems:'center',
              minHeight:60
            }}
          >
            <div
              style={{
                backgroundColor:'transparent',
                minWidth:150,
                marginLeft:20
              }}
            >
            <ComboDependencias />
            </div>
            <div
              style={{
                backgroundColor:'transparent',
                minWidth:120,
                marginLeft:20
              }}
            >
           <ComboTiposApoyo />
            </div>
            <div
              style={{
                backgroundColor:'transparent',
                minWidth:120,
                marginLeft:20,
                alignItems:'center',
                display:'flex',
                flexDirection:'row'
              }}
            ><Typography
              style={{
                color:'white',
                fontSize:12
              }}
            >
              De
            </Typography>
              <TextField
                type='date'
                size='small'
                onChange={(e) => {setInicio(e.target.value)}}
                value={inicio}
                inputProps={{
                  style:{
                    fontSize:12
                  }
                }}
                style={{
                  backgroundColor:'white',
                  borderRadius:15,
                  marginLeft:10,
                  width:140
                }}
              />
            </div>
            <div
              style={{
                backgroundColor:'transparent',
                minWidth:150,
                marginLeft:20,
                alignItems:'center',
                display:'flex',
                flexDirection:'row'
              }}
            ><Typography
              style={{
                color:'white',
                fontSize:12
              }}
            >
              Hasta
            </Typography>
              <TextField
                type='date'
                size='small'
                onChange={(e) => {setFin(e.target.value)}}
                value={fin}
                inputProps={{
                  style: {
                    fontSize:11

                  }
                }}
                style={{
                  backgroundColor:'white',
                  borderRadius:15,
                  marginLeft:10,
                  width:105,
                  fontSize:12
                }}
              />
              <Button
                variant='contained'
                onClick={() => {obtenerDatos()}}
                style={{
                  textTransform:'unset',
                  marginLeft:20,
                  backgroundColor:'white',
                  color:'#B3282D',
                  borderRadius:15
                }}
              >
                Filtrar
              </Button>
            </div>
            <div
              style={{
                backgroundColor:'transparent',
//                minWidth:200,
                marginLeft:20,
                alignItems:'center',
                display:'flex',
                flexDirection:'row',
                flex:1,
                justifyContent:'flex-end',
                marginRight:20,
                fontWeight:'600',
                fontSize:16
              }}
            >
              <Typography
                style={{
                  color:'white',
                  fontSize:15,
                  fontWeight:'600',
                  fontSize:16
                }}
              >
                Filtros
              </Typography>
            </div>            
          </div>
          <div
            style={{
              backgroundColor:'white',
              display:'flex',
              flexDirection:'row',
              flex:45
            }}
          >
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                flex:50
              }}
            >
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:60
              }}
            >
              <img
                  style={{
                      width:300,
                      height:'auto',
                      marginTop:30,
                      marginLeft:20
      //                marginTop:'15%'
                  }}
                  src={imagenDashboard}
              />
            </div>
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:40,
                marginTop:20
              }}
            >
              <Typography
                style={{
                  color:'#50B747',
                  fontWeight:'400',
                  fontSize:18
                }}
              >
                Registro Único de Beneficiarios
              </Typography>
              <Typography
                style={{
                  color:'#535272',
                  fontWeight:'400',
                  fontSize:10
                }}
              >
                Registro y control de apoyos
              </Typography>
              <Button
                onClick={() => toPDF()}
                style={{
                  backgroundColor:'#B3282D',
                  marginTop:30,
                  color:'white',
                  textTransform:'unset',
                  maxWidth:200,
                  borderRadius:20
                }}
              >
                Generar Reporte
              </Button>
            </div>
          </div>
          </div>
          <div
              style={{
                display:'flex',
                flexDirection:'row',
                flex:50,
                marginTop:10,
                marginLeft:20,
            //    justifyContent:'space-between'
              }}
            >
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  flex:1,
                  backgroundColor:'transparent',
               //   justifyContent:'space-between'
                }}
              
              >
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:50,
                    
                    //maxHeight:30,
                    minHeight:'100%',
                    borderTopLeftRadius:15,
                    borderTopRightRadius:15,
                    justifyContent:'center',
                    alignItems:'center'
                  }}
                >
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      flex:10,
                      backgroundColor:'#50B747',
                      minWidth:'100%',
                      borderTopLeftRadius:15,
                      borderTopRightRadius:15,
                      justifyContent:'center',

                    }}
                  >
                    <Typography
                      style={{
                        color:'white',
                        fontWeight:'600'
                      }}
                    >
                      Beneficiarios
                    </Typography>
                  </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:90,
                    backgroundColor:'transparent',
                    marginTop:0,
                    alignItems:'center',
                    minWidth:'100%',
                    marginTop:20,
                    marginLeft:20
                    //minHeight:'100%'
                    
                  }}
                >
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      flex:1,
                      backgroundColor:'transparent',
                      minWidth:'80%',
                      alignItems:'center',
                      justifyContent:'space-between',
                      marginRight:30
                    }}
                  >
                  <img
                      style={{
                          width:100,
                          height:'auto',
          //                marginTop:'15%'
                      }}
                      src={graficaOnda}
                    />
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'row'
                      }}
                    >
                      <Typography
                        style={{
                          fontSize:40,
                          fontWeight:'600'
                        }}
                      >
                        +{datos?.beneficiarios?.total}
                      </Typography>
                      <Typography
                        style={{
                          fontSize:11,
                          fontWeight:'600',
                          color:'#4A9D77',
                          marginLeft:15
                        }}
                      >
                        +{datos?.beneficiarios?.variacionMesAnterior}%
                      </Typography>
                    </div>
                  </div>
                </div>

                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:50,
                    
                    //maxHeight:30,
                    minHeight:'100%',
                    borderTopLeftRadius:15,
                    borderTopRightRadius:15,
                    justifyContent:'center',
                    alignItems:'center',
                    marginLeft:20
                  }}
                >
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      flex:10,
                      backgroundColor:'#B3282D',
                      minWidth:'100%',
                      borderTopLeftRadius:15,
                      borderTopRightRadius:15,
                      justifyContent:'center'
                    }}
                  >
                    <Typography
                      style={{
                        color:'white',
                        fontWeight:'600'
                      }}
                    >
                      Apoyos
                    </Typography>
                  </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:90,
                    backgroundColor:'transparent',
                    marginTop:0,
                    alignItems:'center',
                    minWidth:'100%',
                    marginTop:20,
                    marginLeft:20
                    //minHeight:'100%'
                    
                  }}
                >
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      flex:1,
                      backgroundColor:'transparent',
                      minWidth:'80%',
                      alignItems:'center',
                      justifyContent:'space-between',
                      marginRight:30
                    }}
                  >
                  <img
                      style={{
                          width:100,
                          height:'auto',
          //                marginTop:'15%'
                      }}
                      src={graficaOnda}
                    />
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'row'
                      }}
                    >
                      <Typography
                        style={{
                          fontSize:40,
                          fontWeight:'600'
                        }}
                      >
                        +{datos?.apoyos?.total}
                      </Typography>
                      <Typography
                        style={{
                          fontSize:11,
                          fontWeight:'600',
                          color:'#4A9D77',
                          marginLeft:15
                        }}
                      >
                        +4.5%
                      </Typography>
                    </div>
                </div>
</div>
                </div>
 
          
        </div>
        </div>
        </div>
        <div
          style={{
            display:'flex',
            flexDirection:'column',
            flex:65,
            backgroundColor:'white',
            borderRadius:20,
            marginLeft:30,
            minWidth:300
            //padding:2
          }}
        >

          <div
            style={{
              display:'flex',
              flexDirection:'row',
              marginLeft:0,
              marginTop:0,
              padding:0
            }}
          >

            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:90,
                marginLeft:0,
                padding:0,
                
                //minWidth:400
              }}
            >
                 <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      flex:1,
                      minWidth:'100%',
                      backgroundColor:'#50B747',
                      borderTopLeftRadius:15,
                      borderTopRightRadius:15,
                      justifyContent:'center',
                      alignItems:'center'
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight:'600',
                        color:'white'
                      }}
                    >
                      Tipos de Apoyo
                    </Typography>
                    </div>    
              {
                datos?.apoyos?.tipo?.map((apoyo, index) => (
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  flex:1,
                  marginTop:10,
                  marginLeft:20
                }}
              >
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:30
              }}
            >
                 <img
                      style={{
                          width:52,
                          height:'auto',
          //                marginTop:'15%'
                      }}
                      src={regalo}
                    />
            </div>                
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:70
                  }}
                >
                  <Typography
                    style={{
                      fontSize:15,
                      fontWeight:'500'

                    }}
                  >
                    {apoyo?.tipoApoyo}
                  </Typography>
                  {/* <Typography
                    style={{
                      fontSize:11,
                      fontWeight:'300'

                    }}
                  >
                    Datoos adicionales
                  </Typography> */}
                  </div>
             
          <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:30
              }}
            >
                 <Typography
                  style={{
                    fontSize:20
                  }}
                 >
                  {apoyo?.total}
                 </Typography>
                 </div>
</div>
))
}

            </div>
          </div>

        </div>
      </div>
      <div
      style={{
          display:'flex',
          flexDirection:'row',
          flex:45,
          justifyContent:'flex-start',
          marginTop:20,
          marginRight:20,
          marginBottom:20
        }}
      >
        <div
          style={{
            display:'flex',
            flexDirection:'row',
            flex:75,
            backgroundColor:'transparent',
            borderRadius:20,
            marginLeft:0
          }}
        >
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:40,
              }}
              >
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  flex:20,
                  backgroundColor:'#F8F8FB',
                  borderRadius:15
                }}
              >
 
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:50
                  }}
                >
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:10,
                      fontSize:10,
                      backgroundColor:'transparent',
                      alignItems:'center',
                      marginLeft:10
                    }}
                  >
                    <Typography style={{fontWeight:'600', fontSize:10, marginRight:5}}>Hoy</Typography> {dayjs().format('DD/MM/YYYY')}
                  </div>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:10,
                      fontSize:10,
                      backgroundColor:'transparent',
                      alignItems:'center',
                      marginLeft:10
                    }}
                  >
                    <Typography style={{fontWeight:'600', fontSize:18, marginRight:5}}>Verificados</Typography> 
                  </div>
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:30,
                    justifyContent:'center'
                  }}
                >
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      fontSize:30,
                      fontWeight:'600'
                    }}
                  >
                    <div>
                    {datos?.beneficiarios?.verificados || 0}
                    </div>
                    <div
                      style={{
                        borderRadius:50,
                        backgroundColor:'#E2F4EC',
                        padding:6,
                        marginLeft:20,
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'center',
                        alignItems:'center',
                        width:30,
                        height:30
                      }}
                    >
                      <BadgeOutlined style={{
                        fontSize:25,
                        color:'#5C9E7B'
                      }} />
                    </div>
                  </div>
                  
                </div> 
              </div>
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  flex:80,
                  backgroundColor:'white',
                  marginTop:20,
                  borderRadius:15
                }}
              >
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:1
                  }}
                >
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      flex:25,
                      marginTop:10,
                      marginLeft:10,
                      fontSize:11,
                      alignItems:'flex-end'
                    }}
                  >
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'row',
                        flex:70,
                        fontSize:18,
                        fontWeight:'700',
                        //alignItems:'flex-end'
                      }}
                    >
                    Incompletos
                    </div>
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'row',
                        flex:20,
                        fontSize:35,
                        fontWeight:'600',
                        justifyContent:'flex-end',
                        marginRight:20
                      }}
                    >
                      {datos?.beneficiarios?.incompletos?.total}
                      <div
                      style={{
                        borderRadius:50,
                        backgroundColor:'#FFEDEF',
                        padding:6,
                        marginLeft:20,
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'center',
                        alignItems:'center',
                        width:30,
                        height:30
                      }}
                    >
                      <BadgeOutlined style={{
                        fontSize:25,
                        color:'#E95060'
                      }} />
                    </div>

                    </div>
                  </div>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      flex:25,
                      marginTop:5,
                      marginLeft:50,
                      fontSize:15,
                      alignItems:'flex-end',
                      fontWeight:'500',
                      alignItems:'center'
                    }}
                  >
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:40
                      }}
                    >
                      INE
                    </div>
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:30,
                        backgroundColor:'#FFEDEF',
                        alignItems:'center',
                        borderRadius:10,
                        paddingTop:2,
                        paddingBottom:2
                      }}
                    >
                      <Typography
                        style={{
                          fontSize:12,
                          color:'#E95060'
                        }}
                      >
                        {((datos?.beneficiarios?.incompletos?.ine/datos?.beneficiarios?.incompletos?.total)*100).toFixed(2)}%
                      </Typography>
                    </div>
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:30,
                        alignItems:'flex-end',
                        marginRight:20,
                        fontSize:13
                      }}
                    >
                        {datos?.beneficiarios?.incompletos?.ine}
                        </div>
                  </div>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      flex:25,
                      marginTop:10,
                      marginLeft:50,
                      fontSize:15,
                      alignItems:'flex-end',
                      fontWeight:'500',
                      alignItems:'center'
                    }}
                  >
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:40
                      }}
                    >
                      Fotografía
                    </div>
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:30,
                        backgroundColor:'#E1E1EF',
                        alignItems:'center',
                        borderRadius:10,
                        paddingTop:5,
                        paddingBottom:5
                      }}
                    >
                      <Typography
                        style={{
                          fontSize:12,
                          color:'#363565'
                        }}
                      >
                        {((datos?.beneficiarios?.incompletos?.fotografia/datos?.beneficiarios?.incompletos?.total)*100).toFixed(2)}%
                      </Typography>
                    </div>
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:30,
                        alignItems:'flex-end',
                        marginRight:20,
                        fontSize:13
                      }}
                    >
                        {datos?.beneficiarios?.incompletos?.fotografia}
                        </div>
                  </div>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      flex:25,
                      marginTop:10,
                      marginLeft:50,
                      fontSize:15,
                      alignItems:'flex-end',
                      fontWeight:'500',
                      alignItems:'center'
                    }}
                  >
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:40
                      }}
                    >
                      CURP
                    </div>
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:30,
                        backgroundColor:'#E2F4EC',
                        alignItems:'center',
                        borderRadius:10,
                        paddingTop:2,
                        paddingBottom:2
                      }}
                    >
                      <Typography
                        style={{
                          fontSize:12,
                          color:'#4A9D77'
                        }}
                      >
                        {((datos?.beneficiarios?.incompletos?.curp/datos?.beneficiarios?.incompletos?.total)*100).toFixed(2)}%
                      </Typography>
                    </div>
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:30,
                        alignItems:'flex-end',
                        marginRight:20,
                        fontSize:13
                      }}
                    >
                        {datos?.beneficiarios?.incompletos?.curp}
                        </div>
                  </div>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      flex:25,
                      marginTop:5,
                      marginLeft:50,
                      fontSize:15,
                      alignItems:'flex-end',
                      fontWeight:'500',
                      alignItems:'center'
                    }}
                  >
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:40
                      }}
                    >
                      Teléfono
                    </div>
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:30,
                        backgroundColor:'#FFEDEF',
                        alignItems:'center',
                        borderRadius:10,
                        paddingTop:2,
                        paddingBottom:2
                      }}
                    >
                      <Typography
                        style={{
                          fontSize:12,
                          color:'#E95060'
                        }}
                      >
                        {((datos?.beneficiarios?.incompletos?.telefono/datos?.beneficiarios?.incompletos?.total)*100).toFixed(2)}%
                      </Typography>
                    </div>
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:30,
                        alignItems:'flex-end',
                        marginRight:20,
                        fontSize:13
                      }}
                    >
                        {datos?.beneficiarios?.incompletos?.telefono  }
                        </div>
                  </div>

                </div>
              </div>

            </div>
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:20,
                marginLeft:20
              }}
            >
               <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  flex:19,
                  backgroundColor:'#F8F8FB',
                  borderRadius:15,
                  
                }}
              >
                <div
                  style={{
                    display:'flex',
                    flexDirection:'row',
                    flex:1,
                    marginTop:0,
                    marginLeft:10
                  }}
                >
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'column',
                      flex:70,
                      justifyContent:'center'
                    }}
                  >
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'row',
                        fontSize:11,
                        color:'#535272'
                      }}
                    >
                      Apoyos hoy
                      
                    </div>
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'row',
                        fontSize:20,
                        fontWeight:'600',
                        marginTop:5
                      }}
                    >
                      {datos?.apoyos?.hoy}
                    </div>
                  </div>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'column',
                      flex:30,
                      marginRight:10,
                      justifyContent:'center'
                    }}
                  >
                    <div
                      style={{
                        borderRadius:50,
                        backgroundColor:'#E2F4EC',
                        padding:6,
                        marginLeft:20,
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'center',
                        alignItems:'center',
                        width:30,
                        height:30
                      }}
                    >
                      <Redeem style={{
                        fontSize:25,
                        color:'#5C9E7B'
                      }} />
                    </div>

                  </div>
                </div>   
              </div>
               <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  flex:20,
                  backgroundColor:'#F8F8FB',
                  borderRadius:15,
                  marginTop:20,
                  
                }}
              >
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'column',
                      flex:70,
                      justifyContent:'center',
                      marginLeft:10
                    }}
                  >
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'row',
                        fontSize:11,
                        color:'#535272'
                      }}
                    >
                      Beneficiados hoy
                      
                    </div>
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'row',
                        fontSize:20,
                        fontWeight:'600',
                        marginTop:5
                      }}
                    >
                      {datos?.apoyos?.beneficiados}
                    </div>
                  </div>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'column',
                      flex:30,
                      marginRight:10,
                      justifyContent:'center'
                    }}
                  >
                    <div
                      style={{
                        borderRadius:50,
                        backgroundColor:'#E2F4EC',
                        padding:6,
                        marginLeft:20,
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'center',
                        alignItems:'center',
                        width:30,
                        height:30
                      }}
                    >
                      <Groups style={{
                        fontSize:25,
                        color:'#5C9E7B'
                      }} />
                    </div>

                  </div>
                
              </div>
               <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  flex:50,
                  backgroundColor:'#50B747',
                  borderRadius:15,
                  marginTop:20
                }}
              >
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    marginLeft:20,
                    justifyContent:'space-around',
                    borderRadius:15
                  }}
                >
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      fontSize:12,
                      color:'white',
                      marginTop:5,
                      fontWeight:'600'
                    }}
                  >
                    Escala de Satisfacción
                  </div>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      fontSize:30,
                      color:'white',
                      marginTop:5,
                      fontWeight:'600',
                      color:'#2B6526'
                    }}
                  >
                    0
                  </div>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      fontSize:16,
                      color:'white',
                      marginTop:5,
                      fontWeight:'600'
                    }}
                  >
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:1,
                        alignItems:'center'
                      }}
                    >
                      <SentimentSatisfiedAlt style={{
                        fontSize:40,
                        color:'white'
                      }} />
                      <Typography
                        style={{
                          fontSize:12
                        }}
                      >
                        0%
                      </Typography>
                    </div>
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:1,
                        alignItems:'center'
                      }}
                    >
                      <SentimentDissatisfied style={{
                        fontSize:40,
                        color:'white'
                      }} />
                      <Typography
                        style={{
                          fontSize:12
                        }}
                      >
                        0%
                      </Typography>
                    </div>
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:1,
                        alignItems:'center'
                      }}
                    >
                      <SentimentDissatisfiedOutlined style={{
                        fontSize:40,
                        color:'white'
                      }} />
                      <Typography
                        style={{
                          fontSize:12
                        }}
                      >
                        0%
                      </Typography>
                    </div>
                    
                  </div>
                </div>
              </div>

            </div>
            <div
              style={{
                display:'flex',
                flexDirection:'column',
                flex:40,
            //    justifyContent:'space-around',
                backgroundColor:'transparent'
                //minWidth:'100%'
              }}
              >
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  flex:20,
                  backgroundColor:'white',
                  borderRadius:15,
                  marginLeft:20,
                  
                  //minWidth:'100%'
                }}
              >
                <div
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    minWidth:'100%',
                    justifyContent:'space-around',
                    borderBottomLeftRadius:15,
                    borderBottomRightRadius:15,
                    borderBottomLeftRadius:15,
                    borderBottomRightRadius:15,

                  }}
                >
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      flex:1,
                      minWidth:'100%',
                      backgroundColor:'#50B747',
                      borderTopLeftRadius:15,
                      borderTopRightRadius:15,
                      justifyContent:'center',
                      alignItems:'center'
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight:'600',
                        color:'white'
                      }}
                    >
                      Población
                    </Typography>
                  </div>
                      <div
                        style={{
                          display:'flex',
                          flexDirection:'row',
                          backgroundColor:'transparent',
                          marginTop:20,
                          marginLeft:30,
                          fontWeight:'600',
                          alignItems:'flex-start',
                          
                        }}
                      >
                        <Typography
                          style={{
                            fontSize:25,
                            color:'#50B747',
                            fontWeight:'700'
                          }}
                        >

                          {datos?.beneficiarios?.total}
                        </Typography>
                        <Typography
                          style={{
                            fontSize:13,
                            color:'#50B747',
                            fontWeight:'700',
                            marginLeft:10,
                            marginTop:5
                          }}
                        >

                          RUB
                        </Typography>
                      </div>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      flex:9,
                      minWidth:'100%',
                      backgroundColor:'white',
                      justifyContent:'space-around',
                      borderRadius:15
                    }}
                  >
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'space-around',
                        flex:1,
                        borderRadius:15,
                      }}
                    >

                    <div
                      style={{
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'center',
                        alignItems:'center',
                        flex:1,
                        backgroundColor:'transparent',
                        maxHeight:190
                      }}
                    >
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '170px',
      width: '170px'
    }}>
      <div style={{
        width: '150px',
        height: '150px',
        borderRadius: '50%',
        background: 'conic-gradient(#50B747 '+(datos?.apoyos?.total/1512450)*100+'%, #fff '+(datos?.apoyos?.total/1512450)*100+'% 100%)',
        position: 'relative',
        
      }}>
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '120px',
          height: '120px',
          borderRadius: '50%',
          backgroundColor: '#fff',
          border: '10px solid #363565'
        }} />
      </div>
      <div
        style={{
          position:'absolute',
          width:'200px',
          height:'200px',
          display:'flex',
          justifyContent:'center',
          alignItems:'center'
        }}
      >
                            <div
                      style={{
                        display:'flex',
                        flexDirection:'column',
                        flex:1,
                        alignItems:'center',
                        
                      }}
                    >
                      <div
                        style={{
                          backgroundColor:'#E2F4EC',
                          padding:10,
                          borderRadius:20
                        }}
                      >
                      <SentimentVerySatisfiedOutlined style={{
                        fontSize:40,
                        color:'#50B747'
                      }} />
                      </div>
                      <Typography
                        style={{
                          fontSize:20,
                          color:'#50B747',
                          fontWeight:'600'
                        }}
                      >
                        {((datos?.apoyos?.total/1512450)*100).toFixed(2)}%
                      </Typography>
                    </div>
      </div>
    </div>
                    </div>
                  
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      alignItems:'flex-start',
                      backgroundColor:'transparent',
                      minWidth:'100%'

                    }}
                  >
                    <Typography
                      style={{
                        fontSize:25,
                        color:'#363565',
                        marginLeft:30,
                        fontWeight:'700'
                      }}
                    >
                      1,512,450
                    </Typography>
                    <Typography
                      style={{
                        fontSize:12,
                        color:'#363565',
                        marginLeft:30,
                        fontWeight:'700',
                        marginTop:5
                      }}
                    >
                      Total
                    </Typography>
                  </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        <div
          style={{
            display:'flex',
            flexDirection:'column',
            flex:25,
            backgroundColor:'white',
            borderRadius:20,
            marginLeft:30
          }}
        >
          <div
            style={{
              display:'flex',
              flexDirection:'column',
              marginTop:0,
              marginLeft:0
            }}
          >
                 <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      flex:1,
                      minWidth:'100%',
                      backgroundColor:'#50B747',
                      borderTopLeftRadius:15,
                      borderTopRightRadius:15,
                      justifyContent:'center',
                      alignItems:'center'
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight:'600',
                        color:'white'
                      }}
                    >
                      Colonias
                    </Typography>
                    </div>                
           {datos?.apoyos?.colonia?.map((colonia, index) => (

          
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                marginTop:10,
                marginLeft:20
              }}
            >
              <div
                style={{
                  display:'flex',
                  flexDirection:'column',
                  flex:30
                }}
              >
                  <img
                        style={{
                            width:52,
                            height:'auto',
            //                marginTop:'15%'
                        }}
                        src={sector}
                      />
              </div>        
              <div
                style={{
                  display:'flex',
                  flexDirection:'column',
                  flex:70,
                  justifyContent:'center'
                }}
              >
                {colonia?.colonia}
              </div>
              <div
                style={{
                  display:'flex',
                  flexDirection:'column',
                  flex:30,
                  justifyContent:'center'
                }}
              >
                {colonia?.total}
              </div>
            </div>
             ))} 
          </div>
          
        </div>
      </div>
    </div>
    </>
  )
}
