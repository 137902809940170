import { Button, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { ObtenerDatos } from '../helpers/ApiHelper'
import dayjs from 'dayjs'
import { Camera, CameraAlt, Photo } from '@mui/icons-material'
import { ModalImagenesEvidencia } from './ModalImagenesEvidencia'

export const ModalApoyosBrindados = () => {
    const {modalApoyosBrindados, setModalApoyosBrindados, beneficiarioSeleccionado, setModalImagenesEvidencia} = useContext(AuthContext)
    const [apoyos, setApoyos] = useState()
const [apoyoSeleccionado, setApoyoSeleccionado] = useState()

    const handleClose = () => {
        setModalApoyosBrindados(false)
    }

    const obtenerApoyos = () => {
        const data = new FormData();
        data.append('idBeneficiario', beneficiarioSeleccionado?.idBeneficiario)
        ObtenerDatos('/apoyos.php?funcion=apoyosBeneficiario', data).then(response => {
            console.log('Respuesta de apoyos', response)
            setApoyos(response)
        })
    }

    useEffect(() => {
        if(modalApoyosBrindados) obtenerApoyos()
    }, [modalApoyosBrindados])
    

  return (
    <Dialog
        size="md"
        fullWidth
        open={modalApoyosBrindados}
        onClose={handleClose}
    >
        <DialogContent>
            <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:1
                }}
            >
                <div
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        backgroundColor:'#B3282D',
                        paddingBottom:20,
                        paddingTop:20,
                        padding:20,
                        borderTopLeftRadius:15,
                        borderTopRightRadius:15
                    }}
                >
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        Fecha
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        Autorizó
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        Apoyo
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        Comentarios
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        Evidencia
                    </div>
                </div>
                {
                    apoyos?.map((apoyo, index) => (

                <div
                    key={index}
                    style={{
                        display:'flex',
                        flexDirection:'row',
                        backgroundColor:'white',
                        paddingBottom:20,
                        paddingTop:20,
                        padding:20,
                        fontSize:12
                    }}
                >
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        {dayjs(apoyo.fecha).format('DD/MM/YYYY')}
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        {apoyo?.nombreCompleto}
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        {apoyo?.tipoApoyo}
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20
                        }}
                    >
                        {apoyo?.comentarios}
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            flex:20,
                            alignItems:'center'
                        }}
                    >
                        <IconButton
                            onClick={() => {setApoyoSeleccionado(apoyo); setModalImagenesEvidencia(true)}}
                        >
                        <CameraAlt
                            style={{
                                color:apoyo?.evidencia.length>0 ? '#00BA00':'black'
                            }}
                        />
                        </IconButton>
                    </div>
                </div>
                ))
            }
                
            </div>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleClose}
                style={{
                    textTransform:'unset'
                }}
            >
                Cerrar
            </Button>
        </DialogActions>
        <ModalImagenesEvidencia apoyoSeleccionado={apoyoSeleccionado}  />
    </Dialog>
  )
}
