import { Notifications, Person } from '@mui/icons-material'
import { Alert } from '@mui/material'
import React, { useContext } from 'react'
import { AuthContext } from '../context/AuthContext'

export const UsuarioComponent = () => {
    const {beneficiarioSeleccionado, usuario} = useContext(AuthContext)

    function padNumber(number, length) {
        // Convert the number to a string
        const numberString = String(number);
        // Pad the string with zeros on the left
        const paddedString = numberString.padStart(length, '0');
        return paddedString;
    }

  return (
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            marginLeft:30
        }}
    >
        <Notifications />
        <img 
            src={'https://rubjrz.com/imagenes/usuarios/'+padNumber(usuario.Usuarios_IdUsuario,6)+'.jpg'} alt="Image from remote URL" 
            style={{
                width:30,
                height:'auto',
                marginLeft:30,
                borderRadius:15
            }}
        /> 


    </div>
  )
}
