import React, { useContext } from 'react'
import logoMenu from  '../assets/logoMenu.png'
import { AuthContext } from '../context/AuthContext'
import { FourGPlusMobiledataSharp, Group, HighQuality, Home, Logout, Loupe, LoupeRounded, Map, Person2, Person3, PictureAsPdf, Search } from '@mui/icons-material'

export const MenuComponent = () => {
    const {opcionSeleccionada, setOpcionSeleccionada, setLogueado} = useContext(AuthContext)

  return (
    <div
        style={{
            backgroundColor:'#B3282D',
            display:'flex',
            flex:1,
            height:'97%',
            margin:10,
            borderRadius:20,
            flexDirection:'column'
        }}
    >
        <img
            style={{
                width:'80%',
                height:'auto',
                marginTop:'15%',
                marginLeft:20
            }}
            src={logoMenu}
        />
        <div
             onClick={() => {setOpcionSeleccionada('dashboard')}}
             style={{
                marginTop:'10%',
                backgroundColor:opcionSeleccionada=='dashboard' ? 'white':'#B3282D',
                marginLeft:20,
                height:'5%',
                borderTopLeftRadius:20,
                borderBottomLeftRadius:20,
                alignItems:'center',
                display:'flex',
                flexDirection:'row',
                paddingLeft:30,
                cursor:'pointer'
                
            }}
        >
            <div
                style={{
                    display:'flex',
                    flex:15
                }}
            >
                <Home 
                    style={{
                        color:opcionSeleccionada=='dashboard' ? '#B3282D':'white'
                    }}
                />
            </div>
            <div
                style={{
                    display:'flex',
                    flex:85,
                    color:opcionSeleccionada=='dashboard' ? '#B3282D':'white'
                }}
            >
                Dashboard
            </div>
        </div>
        <div
            onClick={() => {setOpcionSeleccionada('beneficiarios')}}
            style={{
                marginTop:'0%',
                backgroundColor:opcionSeleccionada=='beneficiarios' ? 'white':'#B3282D',
                marginLeft:20,
                height:'5%',
                borderTopLeftRadius:20,
                borderBottomLeftRadius:20,
                alignItems:'center',
                display:'flex',
                flexDirection:'row',
                paddingLeft:30,
                cursor:'pointer'
                
            }}
        >
            <div
                style={{
                    display:'flex',
                    flex:15
                }}
            >
                <Person2 
                    style={{
                        color:opcionSeleccionada=='beneficiarios' ? '#B3282D':'white'
                    }}
                />
            </div>
            <div
                style={{
                    display:'flex',
                    flex:85,
                    color:opcionSeleccionada=='beneficiarios' ? '#B3282D':'white'
                }}
            >
                Beneficiarios
            </div>
        </div>
        <div
            onClick={() => {setOpcionSeleccionada('ciudad')}}
            style={{
                marginTop:'0%',
                backgroundColor:opcionSeleccionada=='ciudad' ? 'white':'#B3282D',
                marginLeft:20,
                height:'5%',
                borderTopLeftRadius:20,
                borderBottomLeftRadius:20,
                alignItems:'center',
                display:'flex',
                flexDirection:'row',
                paddingLeft:30,
                cursor:'pointer'
                
            }}
        >
            <div
                style={{
                    display:'flex',
                    flex:15
                }}
            >
                <Map 
                    style={{
                        color:opcionSeleccionada=='ciudad' ? '#B3282D':'white'
                    }}
                />
            </div>
            <div
                style={{
                    display:'flex',
                    flex:85,
                    color:opcionSeleccionada=='ciudad' ? '#B3282D':'white'
                }}
            >
                Ciudad
            </div>
        </div>
        <div
            onClick={() => {setOpcionSeleccionada('usuarios')}}
            style={{
                marginTop:'0%',
                backgroundColor:opcionSeleccionada=='usuarios' ? 'white':'#B3282D',
                marginLeft:20,
                height:'5%',
                borderTopLeftRadius:20,
                borderBottomLeftRadius:20,
                alignItems:'center',
                display:'flex',
                flexDirection:'row',
                paddingLeft:30,
                cursor:'pointer'
                
            }}
        >
            <div
                style={{
                    display:'flex',
                    flex:15
                }}
            >
                <Group
                    style={{
                        color:opcionSeleccionada=='usuarios' ? '#B3282D':'white'
                    }}
                />
            </div>
            <div
                style={{
                    display:'flex',
                    flex:85,
                    color:opcionSeleccionada=='usuarios' ? '#B3282D':'white'
                }}
            >
                Usuarios
            </div>
        </div>
        <div
            onClick={() => {setOpcionSeleccionada('verificacion')}}
            style={{
                marginTop:'0%',
                backgroundColor:opcionSeleccionada=='verificacion' ? 'white':'#B3282D',
                marginLeft:20,
                height:'5%',
                borderTopLeftRadius:20,
                borderBottomLeftRadius:20,
                alignItems:'center',
                display:'flex',
                flexDirection:'row',
                paddingLeft:30,
                cursor:'pointer'
                
            }}
        >
            <div
                style={{
                    display:'flex',
                    flex:15
                }}
            >
                <Search
                    style={{
                        color:opcionSeleccionada=='verificacion' ? '#B3282D':'white'
                    }}
                />
            </div>
            <div
                style={{
                    display:'flex',
                    flex:85,
                    color:opcionSeleccionada=='verificacion' ? '#B3282D':'white'
                }}
            >
                Verificación
            </div>
        </div>
        <div
            onClick={() => {setOpcionSeleccionada('resumen')}}
            style={{
                marginTop:'0%',
                backgroundColor:opcionSeleccionada=='resumen' ? 'white':'#B3282D',
                marginLeft:20,
                height:'5%',
                borderTopLeftRadius:20,
                borderBottomLeftRadius:20,
                alignItems:'center',
                display:'flex',
                flexDirection:'row',
                paddingLeft:30,
                cursor:'pointer'
                
            }}
        >
            <div
                style={{
                    display:'flex',
                    flex:15
                }}
            >
                <PictureAsPdf  
                    style={{
                        color:opcionSeleccionada=='resumen' ? '#B3282D':'white'
                    }}
                />
            </div>
            <div
                style={{
                    display:'flex',
                    flex:85,
                    color:opcionSeleccionada=='resumen' ? '#B3282D':'white'
                }}
            >
                Resumen
            </div>
        </div>
        <div
            onClick={() => {setOpcionSeleccionada('cerrar'); setLogueado(false)}}
            style={{
                marginTop:'0%',
                backgroundColor:opcionSeleccionada=='cerrar' ? 'white':'#B3282D',
                marginLeft:20,
                height:'5%',
                borderTopLeftRadius:20,
                borderBottomLeftRadius:20,
                alignItems:'center',
                display:'flex',
                flexDirection:'row',
                paddingLeft:30,
                cursor:'pointer'
                
            }}
        >
            <div
                style={{
                    display:'flex',
                    flex:15
                }}
            >
                <Logout  
                    style={{
                        color:opcionSeleccionada=='cerrar' ? '#B3282D':'white'
                    }}
                />
            </div>
            <div
                style={{
                    display:'flex',
                    flex:85,
                    color:opcionSeleccionada=='cerrar' ? '#B3282D':'white'
                }}
            >
                Cerrar sesión
            </div>
        </div>
    </div>
  )
}
