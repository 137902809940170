import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { AuthContext } from './context/AuthContext';
import { LoginPage } from './pages/LoginPage';
import { PrincipalPage } from './pages/PrincipalPage';
import { DashboardPage } from './pages/DashboardPage';
import { LoginMobile } from './pages/LoginMobile';
import { AgregarBeneficiario } from './pages/AgregarBeneficiario';
import { OpcionesMovil } from './pages/OpcionesMovil';
import { UsuariosProvider } from './context/UsuariosContext';

function App() {
  const {logueado, obtenerConfiguracion} = useContext(AuthContext);
  const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  console.log('es celular?', isMobile())
  useEffect(() => {
    obtenerConfiguracion();
    console.log('Logueado', logueado);
  }, [])
  return (
    <Routes>
    {
      !logueado ? (
        !isMobile() ? (
        <>
          <Route path="/" element={<LoginPage />} /> 
          <Route name="login" path="/demo" element={<LoginPage />} />
        </>
        ):(
          <>
          <Route path="/" element={<LoginMobile />} /> 
          <Route name="login" path="/demo" element={<LoginMobile />} />
          </>
        )
      )
      :
      (
        !isMobile() ? (
        <>
        <Route name="home" path="/" element={<PrincipalPage />} >
          <Route exact path="/dashboard" element={<DashboardPage />} />
        </Route>
        </>
        ):(
          <>
            <Route exact path="/" element={<UsuariosProvider><OpcionesMovil /></UsuariosProvider>} >
              <Route exact path="/dashboard" element={<UsuariosProvider><OpcionesMovil /></UsuariosProvider>} />
            </Route>
          </>
        )
      )
    }
  </Routes>
  );
}

export default App;
