import React, { useEffect, useState } from 'react'
import { ObtenerDependencias } from '../helpers/DependenciasHelper'
import { Autocomplete, TextField } from '@mui/material'
import Select from 'react-select';
import { ObtenerGeneros } from '../helpers/GenerosHelper';

const useGeneros = () => {
    const [generos, setGeneros] = useState();
    const [generoSeleccionado, setGeneroSeleccionado] = useState();

    const obtenerGeneros = () => {
        ObtenerGeneros().then(response => {
            let opciones = new Array();

            response?.map(genero => {
                opciones.push({value:genero.idGenero,label:genero.genero})
            })

            setGeneros(opciones)
            console.log('generos en componente',response)
        });
    }

    useEffect(() => {
        obtenerGeneros();
    }, [])

    useEffect(() => {
        console.log(('genero seleccionado', generoSeleccionado))
    }, [generoSeleccionado])
    
    
    const ComboGeneros = () => {
        return (
            <div>
                <Select
                    onChange={(e) => {setGeneroSeleccionado(e)}}
                    value={generoSeleccionado}
                    options={generos}
                    placeholder="Genero"
                    menuPortalTarget={document.body} 
                    InputLabelProps={{
                        style:{
                            fontSize:11
                        }
                    }}
                    InputProps={{
                        style:{
                            fontSize:11
                        }
                    }}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999, fontSize:11 }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? 'grey' : 'green',
                            fontSize:12,
                            minWidth:150,
                            maxHeight:20,
                            borderRadius:10
                        }),
                    }}
                    style={{
                        width:150,
                        maxHeight:20,
                        menuPortal: base => ({ ...base, zIndex: 999999, fontSize:11 }),
        
                    }}
                />
    </div>
  )
    }
  return {generos, ComboGeneros, generoSeleccionado, setGeneroSeleccionado}
};

export default useGeneros

