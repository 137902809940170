import { AccountCircle, CheckCircle, Man2Outlined, Person, Phone, Room, Search, TransgenderOutlined, Woman2Outlined } from '@mui/icons-material'
import { Button, Divider, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { UsuarioComponent } from '../components/UsuarioComponent'
import useDependencias from '../components/DependenciasComponent'
import useTiposApoyo from '../hooks/useTiposApoyo'
import useGeneros from '../components/GenerosComponent'
import { AuthContext } from '../context/AuthContext'
import logoEncabezado from '../assets/logoEncabezado.png'
import dayjs from 'dayjs'
import GoogleMapReact from 'google-map-react';
import { ObtenerDatos } from '../helpers/ApiHelper'
import { ModalApoyosBrindados } from '../components/ModalApoyosBrindados'
import { ModalImagenesEvidencia } from '../components/ModalImagenesEvidencia'
import { ModalEntregaApoyo } from '../components/ModalEntregaApoyo'

const defaultProps = {
  center: {
    lat: 28.700772688116892,
    lng: -106.53524198508615
  },
  zoom: 7
};

export const CiudadPage = () => {
  const {dependencias, ComboDependencias, dependenciaSeleccionada} = useDependencias();
  const {tipoApoyoSeleccionado, ComboTiposApoyo, obtenerTiposApoyo} = useTiposApoyo();
  const {generoSeleccionado, setGeneroSeleccionado, ComboGeneros} = useGeneros()
  const {beneficiarioSeleccionado, setBeneficiarioSeleccionado, setModalAgregaBeneficiario, setModalEntregaApoyo, modalEntregaApoyo, modalApoyosBrindados, setModalApoyosBrindados} = useContext(AuthContext)
  const [inicio, setInicio] = useState()
  const [fin, setFin] = useState()
  const [beneficiarios, setBeneficiarios] = useState()
  const [busqueda, setBusqueda] = useState('')
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const [layerCiudadanos, setLayerCiudadanos] = useState(false);

  const [datos, setDatos] = useState({
    'accion1':'',
    'hombres':true,
    'mujeres':true,
    'trans':true,
    'telefono':false,
    'idDependencia':0,
    'idTipoApoyo':0,
    'inicio':dayjs().format('YYYY-MM-DD'),
    'final2':dayjs().format('YYYY-MM-DD'),
    'pagina':1
  });

  useEffect(() => {
    console.log('dependencia seleccionada', dependenciaSeleccionada)
    obtenerTiposApoyo(dependenciaSeleccionada?.value)
  }, [dependenciaSeleccionada])
  

  useEffect(() => {
    console.log(dependenciaSeleccionada)
    actualizaDatos('idDependencia', dependenciaSeleccionada?.value || 0)
  }, [dependenciaSeleccionada])
  
  useEffect(() => {
    console.log('tipo de apoyo seleccionado',tipoApoyoSeleccionado)
    actualizaDatos('idTipoApoyo', tipoApoyoSeleccionado?.value || 0)
  }, [tipoApoyoSeleccionado])

  const obtenerBeneficiario = () => {
    ObtenerDatos('/beneficiarios.php?funcion=obtenerBeneficiarioId').then(response => {
      console.log('respuesta beneficiario id', response)
    })
  }

  const actualizaMapa = () => {
  //  console.log('cambiaron los filtros', etiquetaFiltro, nombreFiltro,layerCiudadanos)
    if(layerCiudadanos?.url) {
      const d = new Date();
      const seconds = Math.round(d.getTime() / 1000);
      //layerCiudadanos.setMap(null);
      layerCiudadanos.url='https://rubjrz.com/servicios/generar.capa.beneficiarios.php?v='+seconds+'&hombres='+datos?.hombres+'&mujeres='+datos?.mujeres+'&trans='+datos?.trans+'&telefono='+datos?.telefono+'&idDependencia='+datos?.idDependencia+'&idTipoApoyo='+datos?.idTipoApoyo+'&inicio='+inicio?.target?.value+'&fin='+fin?.target?.value
      layerCiudadanos.setMap(map)
      console.log(layerCiudadanos)
    }
  }

  const loadMap = (map, maps) => {
    console.log('entro a funcion de load map')
 //   console.log('usuarios recibidos en funcion', usuariosFuncion)
    setMap(map);
    setMaps(maps);
    const d = new Date();
    const seconds = Math.round(d.getTime() / 1000);
    // const kmlLayerSecciones = new maps.KmlLayer({
    //     url: 'http://raosari.dyndns.info:8080/decidechih/servicios/kmls/generar.capa.php?v=202203211403&anio='+anio,
    //     suppressInfoWindows: true,
    //     draggable: true,
    //     zIndex:2000,
    //     map:map
    // });
    const data1 = new FormData();
    data1.append('datos', JSON.stringify(datos))
    const kmlLayerCiudadanos = new maps.KmlLayer({

        url: 'https://rubjrz.com/servicios/generar.capa.beneficiarios.php?v='+seconds+'&hombres='+datos?.hombres+'&mujeres='+datos?.mujeres+'&trans='+datos?.trans+'&telefono='+datos?.telefono+'&idDependencia='+datos?.idDependencia+'&idTipoApoyo='+datos?.idTipoApoyo+'&inicio='+inicio?.target?.value+'&fin='+fin?.target?.value,
        suppressInfoWindows: true,
        draggable: true,
        zIndex:3000,
        map:map
    });
    console.log(kmlLayerCiudadanos)
    kmlLayerCiudadanos.addListener('click', function(event) {
      console.log('evento', event)
      const data = new FormData()
      data.append('idBeneficiario', event?.featureData?.name)
      ObtenerDatos('/beneficiarios.php?funcion=beneficiarioID', data).then(response => {
        console.log('response de detalle', response)
        setBeneficiarioSeleccionado(response)
      })      
    })
    setLayerCiudadanos(kmlLayerCiudadanos)
  }


  const actualizaDatos = async (campo, valor) => {
    console.log(campo, valor)
    await setDatos({
      ...datos,
      [campo]:valor
    })
    await setBeneficiarioSeleccionado({
      ...beneficiarioSeleccionado,
      'accion':'editar'
    })
    if(valor=='editar') setModalAgregaBeneficiario(true);
  }

  const obtenerBeneficiarios = () => {

  }

  return (
    <>
    <div
    style={{
      display:'flex',
      flex:1,
      flexDirection:'column',
      marginLeft:20
    }}
  >
    <div
        style={{
          display:'flex',
          flexDirection:'row',
          flex:10,
          justifyContent:'space-between',
          marginTop:20,
          marginRight:20, maxHeight:32,
          alignItems:'center'
        }}
      >
        <div
          style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center'
          }}
        >
     {/* <TextField
      label="Buscar beneficiario por Nombres, #RUB"
      onChange={(e) => {setBusqueda(e.target.value)}}
      InputProps={{
        shrink:true,
        style:{
          backgroundColor:'white',
          borderRadius:10,
          fontSize:12,
          minWidth:500,
          maxHeight:35
        },
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
    <Button
      onClick={() => {buscarBeneficiario()}}
      variant='contained'
      style={{
        backgroundColor:'#B3282D',
        textTransform:'unset',
        paddingLeft:40,
        paddingRight:40,
        maxHeight:30,
        borderRadius:15,
        marginLeft:30
      }}
    >
      <Typography>
        Buscar
      </Typography>
    </Button> */}
    </div>
        <div
          style={{
            display:'flex',
            flexDirection:'row'
            
          }}
        >
        <img
            style={{
                width:200,
                height:'auto',
                
//                marginTop:'15%'
            }}
            src={logoEncabezado}
        />
        <UsuarioComponent /> 
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'column',
          flex:1,
          backgroundColor:'transparent',
          marginLeft:0,
          marginTop:20,
          marginBottom:20,
          borderRadius:15    
        }}
      >
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          marginTop:20,
          backgroundColor:'#B3282D',
          paddingTop:5,
          paddingBottom:5,
          paddingLeft:20,
          borderTopLeftRadius:15,
          borderTopRightRadius:15,
          marginRight:20,
          alignItems:'center',
          justifyContent:'space-between'
        }}
      >
        <div
          style={{
            maxWidth:160
          }}
        >
      <ComboDependencias
        style={{
          width:100
        }}
      />
      </div>
        <div
          style={{
            maxWidth:90,
         //   marginLeft:10
          }}
        >
      <ComboTiposApoyo />
      </div>
      <div
              style={{
                backgroundColor:'transparent',
                minWidth:120,
                marginLeft:70,
                alignItems:'center',
                display:'flex',
                flexDirection:'row',
                alignItems:'center'
              }}
            >
              <Typography
              style={{
                color:'white',
                fontSize:12
              }}
            >
              De
            </Typography>
              <TextField
                type='date'
                size='small'
                onChange={(e) => {setInicio(e)}}
        //        value={dayjs(inicio).format('YYYY-MM-DD')}
                inputProps={{
                  style:{
                    fontSize:12
                  }
                }}
                style={{
                  backgroundColor:'white',
                  borderRadius:15,
                  marginLeft:10,
                  width:140
                }}
              />
            </div>
            <div
              style={{
                backgroundColor:'transparent',
                minWidth:150,
                marginLeft:20,
                alignItems:'center',
                display:'flex',
                flexDirection:'row'
              }}
            ><Typography
              style={{
                color:'white',
                fontSize:12
              }}
            >
              Hasta
            </Typography>
              <TextField
           //     value={dayjs(fin).format('YYYY-MM-DD')}
                type='date'
                size='small'
                onChange={(e) => {setFin(e)}}
                inputProps={{
                  style: {
                    fontSize:11

                  }
                }}
                style={{
                  backgroundColor:'white',
                  borderRadius:15,
                 // marginLeft:10,
                  width:140,
                  fontSize:12
                }}
              />
              </div>      
              <Button
                variant='contained'
                size='small'
                onClick={() => {actualizaDatos('hombres', !datos.hombres)}}

                style={{
                  backgroundColor:'white',
                  maxHeight:'80%',
                  //marginLeft:20,
                  maxWidth:10
                }}
              >
                <Man2Outlined style={{color:'black'}} />
                {
                  datos?.hombres ? (
                <CheckCircle
                  style={{
                    position:'absolute',
                    right:-5,
                    bottom:-5,
                    color:'#00BA00'
                  }}
                />
                  ):null
                }
              </Button>
              <Button
                variant='contained'
                size='small'
                onClick={() => {actualizaDatos('mujeres', !datos.mujeres)}}

                style={{
                  backgroundColor:'white',
                  maxHeight:'80%',
                //  marginLeft:20
                }}
              >
                <Woman2Outlined style={{color:'black'}} />
                {
                  datos.mujeres ? (
                <CheckCircle
                  style={{
                    position:'absolute',
                    right:-5,
                    bottom:-5,
                    color:'#00BA00'
                  }}
                />
                  ):null
              }
              </Button>
              <Button
                variant='contained'
                size='small'
                onClick={() => {actualizaDatos('trans', !datos.trans)}}

                style={{
                  backgroundColor:'white',
                  maxHeight:'80%',
               //   marginLeft:20
                }}
              >
                <TransgenderOutlined style={{color:'black'}} />
                {
                  datos.trans ? (
                    <CheckCircle
                    style={{
                      position:'absolute',
                      right:-5,
                      bottom:-5,
                      color:'#00BA00'
                    }}
                    />
                  ):null
                }
              </Button>
              <Button
                variant='contained'
                size='small'
                onClick={() => {actualizaDatos('telefono', !datos.telefono)}}

                style={{
                  backgroundColor:'white',
                  maxHeight:'80%',
               //   marginLeft:20
                }}
              >
                <Phone style={{color:'black'}} />
                {
                  datos.telefono ? (
                    <CheckCircle
                    style={{
                      position:'absolute',
                      right:-5,
                      bottom:-5,
                      color:'#00BA00'
                    }}
                    />
                  ):null
                }
              </Button>
              <Button
                onClick={() => {actualizaMapa()}}
                size='small'
                style={{
                  textTransform:'unset',
                  backgroundColor:'white',
                  color:'red',
                  borderRadius:20,
               //   marginLeft:20
                }}
              >
                Filtrar
              </Button>
              {/* <Button
                size='small'
                style={{
                  textTransform:'unset',
                  backgroundColor:'white',
                  color:'red',
                  borderRadius:20,
                //  marginLeft:20
                }}
              >
                Exportar
              </Button> */}
              <Typography
                style={{
                  fontSize:20,
                  color:'white',
                  fontWeight:'600',
                //  marginLeft:60,
                  marginRight:20
                }}
              >
                Filtros
              </Typography>
      </div>
    </div>
    <div
      style={{
        display:'flex',
        flexDirection:'row',
        flex:9,
        backgroundColor:'transparent',
        marginBottom:20,
        borderRadius:15,
        marginRight:20
      }}
    >
      <div
        style={{
          display:'flex',
          flexDirection:'column',
          flex:65,
          backgroundColor:'white',
          borderRadius:15
        }}
      >
        <div
          style={{
            display:'flex',
            flexDirection:'row',
            padding:20
          }}
        >
          <Typography
            style={{
              fontWeight:'600'
            }}
          >
            Mapa de Beneficiarios
          </Typography>
          
        </div>
        <Divider />
        <div

            style={{
              width:'100%',
              height:'100%'
            }}
        >
        <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDH9WzJ1Gkjg7VsxW6aSp9v2QJpgx0GVWI", }}
        
        //ref={myRef}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        draggable={true}
        //year={year.value}
        //seccionseleccionada = {seccionseleccionada}
        onDragend= {(evt) => { }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
            //mapRef.current = map;
           loadMap(map, maps);
          //   const d = new Date();
          //   const seconds = Math.round(d.getTime() / 1000);
          //   return(<b>este es un objeto dentro de una funcion</b>)

    
        }}
        onChange={({map, maps}) => {
            //console.log(year.year.value)
            
            //loadMap1(map,maps,year.year.value);
        }}
      >

    </GoogleMapReact>
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'column',
          flex:35,
          marginLeft:20,
          borderRadius:20,
          backgroundColor:'white'
        }}
      >
        {
          beneficiarioSeleccionado?.idBeneficiario ? (
                        <div
                  style={{
                    backgroundColor:'white',
                    padding:0,
                    display:'flex',
                    flexDirection:'column',
                    flex:1,
                    marginTop:10,
                    borderRadius:15
                  }}
                >
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      justifyContent:'center',
                      marginTop:20
                    }}
                  >
                    {/* <img 
                          src="https://rubjrz.com/imagenes/beneficiarios/1.jpg" alt="Image from remote URL" 
                          style={{
                              width:80,
                              height:'auto',
                              marginLeft:40,
                              borderRadius:50,
                              
                          }}
                      /> */}
                                          {
                      beneficiarioSeleccionado?.foto ? (

                    <img 
                          src={beneficiarioSeleccionado?.foto} alt="Image from remote URL" 
                          style={{
                              width:80,
                              height:'auto',
                              marginLeft:40,
                              borderRadius:50,
                              
                          }}
                      />
                      ):(
                        <Person style={{fontSize:50, color:'lightgray'}} />

                      )
                    }

                  </div>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      fontWeight:'600',
                      justifyContent:'center',
                      flex:1,
                      maxHeight:20,
                      marginTop:10,
                      fontWeight:'600'
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight:'600'
                      }}
                    >
                      {beneficiarioSeleccionado?.nombre1+' '+beneficiarioSeleccionado?.nombre2+' '+beneficiarioSeleccionado?.apellidoPaterno+' '+beneficiarioSeleccionado?.apellidoMaterno}
                    </Typography>
                  </div>
                  {
                    dayjs(beneficiarioSeleccionado?.fechaNacimiento).isValid() ? (
                      
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:20,
                      marginRight:0,
                      backgroundColor:'#F9FAFF',
                      paddingTop:10,
                      paddingBottom:10

                    }}
                    >
                    <AccountCircle
                      style={{
                        marginRight:30,
                        color:'#00BA00',
                        marginLeft:20
                        
                      }}
                    />
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column'
                      }}
                    >
                    <Typography>
                      {dayjs(beneficiarioSeleccionado?.fechaNacimiento).locale('es').format('DD [de] MMMM YYYY')}
                    </Typography>
                    <Typography
                      style={{
                        fontSize:12,
                        color:'#00BA00'
                      }}
                    >
                      {dayjs().diff(beneficiarioSeleccionado?.fechaNacimiento,'year')} años
                    </Typography>
                    </div>
                  </div>
                    ):null
                  }
                 <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:20,
                      marginRight:0,
                      backgroundColor:'white',
                      paddingTop:0,
                      paddingBottom:10

                    }}
                    >
                    <Room
                      style={{
                        marginRight:30,
                        marginLeft:20,
                        color:'#00BA00'
                        
                      }}
                    />
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column'
                      }}
                    >
                      {
                        beneficiarioSeleccionado?.direccion ? (

                          <Typography>
                      {beneficiarioSeleccionado?.direccion+' '+beneficiarioSeleccionado?.colonia}
                    </Typography>
                      ):(
                        <Typography>
                          No capturada
                        </Typography>
                      )
                    }
                    </div>
                  </div>     
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:20,
                      marginRight:0,
                      backgroundColor:'#F9FAFF',
                      paddingTop:10,
                      paddingBottom:10

                    }}
                    >
                    <Phone
                      style={{
                        marginRight:30,
                        color:'#00BA00',
                        marginLeft:20
                        
                      }}
                    />
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column'
                      }}
                    >
                    <Typography>
                      {beneficiarioSeleccionado?.telefono}
                    </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:20,
                      marginRight:0,
                      backgroundColor:'white',
                      paddingTop:10,
                      paddingBottom:10

                    }}
                    >
                      <div 
                        onClick={() => {setModalApoyosBrindados(true)}}
                        style={{
                          backgroundColor:'#00BA00',
                          marginLeft:20,
                          marginRight:30,
                          borderRadius:50,
                          width:15,
                          height:15,
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center',
                          padding:5,
                          color:'white',
                          fontSize:11,
                          cursor:'pointer'
                          
                        }}
                      >
                        {beneficiarioSeleccionado?.apoyos}
                      </div>
                    <div
                      style={{
                        display:'flex',
                        flexDirection:'column'
                      }}
                    >
                    <Typography style={{color:'black'}}>
                      Apoyos brindados
                    </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      marginTop:20,
                      marginLeft:20,
                      marginRight:20,
                      justifyContent:'space-between'
                    }}
                  >
                    <Button
                      onClick={() => {
                        setModalEntregaApoyo(true)
                      }}
                      variant='contained'
                      style={{
                        textTransform:'unset',
                        backgroundColor:'white',
                        color:'gray',
                        borderRadius:10

                      }}
                    >
                      Entrega de Apoyo
                    </Button>
                    <Button
                      variant='contained'
                      style={{
                        textTransform:'unset',
                        backgroundColor:'white',
                        color:'gray',
                        borderRadius:10
                      }}
                    >
                      Ficha RUB
                    </Button>
                  </div>

                </div>

          ):null
        }

      </div>
    </div>
  </div>
  <ModalApoyosBrindados />
  <ModalEntregaApoyo />
  
  </>
  )
}
