import { Button, TextField } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { ObtenerDatos } from '../helpers/ApiHelper'
import { ObtenerBeneficiarios } from '../helpers/BeneficiariosHelper'
import useGeneros from '../components/GenerosComponent'
import Swal from 'sweetalert2';
import QrReader from 'modern-react-qr-reader'
import dayjs from 'dayjs'
import { ImageSearch, QrCode } from '@mui/icons-material'

export const AgregarBeneficiario = () => {
    const {modalAgregaBeneficiario, setModalAgregaBeneficiario, beneficiarioSeleccionado} = useContext(AuthContext)
    const {generoSeleccionado, setGeneroSeleccionado, ComboGeneros} = useGeneros()
    const [deshabilitado, setDeshabilitado] = useState(true)

    const [datosBeneficiario, setDatosBeneficiario] = useState({
        'nombre1':'',
        'nombre2':'',
        'apellidoPaterno':'',
        'apellidoMaterno':'',
        'idGenero1':0,
        'fechaNacimiento':'',
        'direccion':'',
        'colonia':'',
        'telefono':'',
        'curp':''
    })
    const [data, setData] = useState('No result');
    const [escaneando, setEscaneando] = useState(false)
    const uploadInputRef = useRef(null);
    const [file, setFile] = useState()
    const uploadInputRef1 = useRef(null);
    const [file1, setFile1] = useState()
    const uploadInputRef2 = useRef(null);
    const [file2, setFile2] = useState()
    const [archivos, setArchivos] = useState([])
    const [image, setImage] = useState(null);
    const formRef = useRef()

    const handleChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setImage(URL.createObjectURL(file));
      }
    };

    useEffect(() => {
        console.log('Archivos seleccionados', file)
     //   console.log('esun arreglo', file[0].name)
        let lista = new Array();
        for(let i=0; i<file?.length;i++) {
            console.log(file[i].name)
            lista.push(file[i].name)
        }
        setArchivos(lista)
    }, [file])

      const SeleccionImagen = (event) => {
        setFile(event.target.files);
        console.log('selecciono imagen',event);
        var reader = new FileReader();
//        reader.onload = function(){
//            imgMilitante.current.src = reader.result;
 //       };
 //       reader.readAsDataURL(event.target.files[0]);
    };

      const SeleccionImagen1 = (event) => {
        setFile1(event.target.files);
        console.log('selecciono imagen',event);
        var reader = new FileReader();
//        reader.onload = function(){
//            imgMilitante.current.src = reader.result;
 //       };
 //       reader.readAsDataURL(event.target.files[0]);
    };

      const SeleccionImagen2 = (event) => {
        setFile2(event.target.files);
        console.log('selecciono imagen',event);
        var reader = new FileReader();
//        reader.onload = function(){
//            imgMilitante.current.src = reader.result;
 //       };
 //       reader.readAsDataURL(event.target.files[0]);
    };


    const handleScan = (data) => {
        if (data) {
          console.log(data)
          
          setData(data)
          const palabras = data.split('|')
          console.log('palabras', palabras)
          const nombres = palabras[4].split(' ')
          var fecha = palabras[6].split('/')
          fecha = fecha[2]+'-'+fecha[1]+'-'+fecha[0]
          var segundoNombre =''
          if(nombres[1]) {
            segundoNombre = nombres[1]
          }
          var genero = palabras[0].substr(10,1)
          if(genero=='H') {
            setGeneroSeleccionado({value:1, label:'Hombre'})
          } else {
            setGeneroSeleccionado({value:1, label:'Mujer'})

          }
          console.log('genero', genero)
          setDatosBeneficiario({
            ...datosBeneficiario,
            curp:palabras[0],
            apellidoPaterno:palabras[2],
            apellidoMaterno:palabras[3],
            nombre1:nombres[0],
            nombre2:segundoNombre,
            fechaNacimiento: fecha,
            idGenero1:genero=='H' ? 1:2
          })
          validaFecha(fecha)
          setEscaneando(false)
        }
      }
      
      const handleError = (err) => {
        console.error(err)
      }

    useEffect(() => {
        if(beneficiarioSeleccionado?.accion=='editar') {
            console.log('editando', beneficiarioSeleccionado)
            setDatosBeneficiario({
                'nombre1':beneficiarioSeleccionado?.nombre1,
                'nombre2':beneficiarioSeleccionado?.nombre2,
                'apellidoPaterno':beneficiarioSeleccionado?.apellidoPaterno,
                'apellidoMaterno':beneficiarioSeleccionado?.apellidoMaterno,
                'idGenero1':beneficiarioSeleccionado?.idGenero,
                'fechaNacimiento':beneficiarioSeleccionado?.fechaNacimiento,
                'direccion':beneficiarioSeleccionado?.direccion,
                'colonia':beneficiarioSeleccionado?.colonia,
                'telefono':beneficiarioSeleccionado?.telefono,
                'idBeneficiario':beneficiarioSeleccionado?.idBeneficiario,
                'curp':beneficiarioSeleccionado?.curp
        
            })
            setGeneroSeleccionado({value:beneficiarioSeleccionado.idGenero, label:beneficiarioSeleccionado?.genero})
        } else {
            console.log('registro nuevo', beneficiarioSeleccionado)
            setDatosBeneficiario({
            'nombre1':'',
            'nombre2':'',
            'apellidoPaterno':'',
            'apellidoMaterno':'',
            'idGenero1':0,
            'fechaNacimiento':'',
            'direccion':'',
            'colonia':'',
            'telefono':'',
            curp:''
            })
        }
    }, [modalAgregaBeneficiario, beneficiarioSeleccionado])


    

    const handleClose =() => {
        setModalAgregaBeneficiario(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        console.log('habilito submit');
        const data1 = new FormData();
        data1.append('datos', JSON.stringify(datosBeneficiario))
        data1.append('archivos', uploadInputRef.current?.files?.length || 0)

        for(let x=0;x<uploadInputRef?.current?.files.length;x++) {
            console.log('frente', uploadInputRef.current?.files[x])
            data1.append('frente', uploadInputRef.current?.files[0]);
        }
        for(let x=0;x<uploadInputRef1?.current?.files.length;x++) {
            console.log('reverso', uploadInputRef1.current?.files[x])
            data1.append('reverso', uploadInputRef1.current?.files[0]);
        }
        for(let x=0;x<uploadInputRef2?.current?.files.length;x++) {
            console.log('beneficiario', uploadInputRef2.current?.files[x])
            data1.append('beneficiario', uploadInputRef2.current?.files[0]);
        }

       // alert('datos a guardar', JSON.stringify(data1))

        ObtenerDatos('/beneficiarios.php?funcion=agregarBeneficiario', data1).then(response => {
            console.log('respuesta del servidor', response, response?.idBeneficiario);
            // setModalAgregaBeneficiario(false);
            // obtenerBeneficiarios();
            if(response?.idBeneficiario) {
                console.log('No se agrego porque ya existe el RFC')
                Swal.fire({
                    position: "top-end",
                    icon: "warning",
                    title: "La CURP ya está registrada con el id RUB: "+response?.idBeneficiario,
                    showConfirmButton: true,
                    //timer: 1500
                  });

            } else {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Beneficiario guardado correctamente, FOLIO: "+response,
                    showConfirmButton: true,
                    //timer: 1500
                  });

            }
        })
        setDatosBeneficiario({
            'nombre1':'',
            'nombre2':'',
            'apellidoPaterno':'',
            'apellidoMaterno':'',
            'idGenero1':0,
            'fechaNacimiento':'',
            'direccion':'',
            'colonia':'',
            'telefono':'',
            curp:''
            })
        setData()

    }

    const validaFecha = (fecha) => {
        console.log('fecha a validar', fecha)
        const diferencia = dayjs().diff(dayjs(fecha), 'year');
        console.log('diferencia años', diferencia)
        if(diferencia<18) {
            setDeshabilitado(true)
        } else {
            setDeshabilitado(false)

        }
    }

    const actualizaDatos = (campo, valor) => {
        setDatosBeneficiario({
            ...datosBeneficiario,
            [campo]:valor
        })
    }

    useEffect(() => {
        console.log('datos de beneficiario', datosBeneficiario)
    }, [datosBeneficiario])

    useEffect(() => {
        actualizaDatos('idGenero1', generoSeleccionado?.value)
    }, [generoSeleccionado])

    useEffect(() => {
        console.log('beneficiario en modal a editar', beneficiarioSeleccionado)
    }, [beneficiarioSeleccionado])
    
   
        
  return (
    <form
    onSubmit={handleSubmit}
    ref={formRef}
>
    <div
        style={{
            marginTop:10,
            color:'black',
            display:'flex',
            flexDirection:'column',
            paddingLeft:10,
            paddingRight:10,
            backgroundColor:'white',
            flex:1,
            height:'100vh'
        }}
    >
        {escaneando  ? (
           <>
           <QrReader
          delay={300}
          facingMode={"environment"}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
      <p>{data}</p>

    </>
    ): (
        <Button
            onClick={() => {setEscaneando(true)}}
            variant='contained'
            style={{
                textTransform:'unset',
                marginBottom:20,
                backgroundColor:'#B3282D'
            }}
        >
            <QrCode />
            Escanear QR CURP
        </Button>
    )
    }
           <TextField
                onChange={(e) => {actualizaDatos('nombre1', e.target.value)}}
                value={datosBeneficiario?.nombre1}
                size='small'
                label="Nombre 1"
                type='text'
                required
                InputLabelProps={{
                    shrink:true,
                }}
           />
            <TextField 
                onChange={(e) => {actualizaDatos('nombre2', e.target.value)}}
                value={datosBeneficiario?.nombre2}
                size='small'
                label="Nombre 2"
                type='text'
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
               
            />
            <TextField 
                onChange={(e) => {actualizaDatos('apellidoPaterno', e.target.value)}}
                value={datosBeneficiario?.apellidoPaterno}
                size='small'
                label="Apellido paterno"
                type='text'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
                
            />
            <TextField 
                onChange={(e) => {actualizaDatos('apellidoMaterno', e.target.value)}}
                value={datosBeneficiario?.apellidoMaterno}
                size='small'
                label="Apellido materno"
                type='text'
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
               
            />
            <div
                style={{
                    marginTop:5
                }}
            >
                <ComboGeneros />

            </div>
            <TextField 
                onChange={(e) => {validaFecha(e.target.value); actualizaDatos('fechaNacimiento', e.target.value); }}
                value={datosBeneficiario?.fechaNacimiento}

                size='small'
                label="Fecha de nacimiento"
                type='date'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
                
            />
            <TextField 
                onChange={(e) => {actualizaDatos('curp', e.target.value)}}
                value={datosBeneficiario?.curp}

                size='small'
                label="CURP"
                type='text'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
                
            />
            <TextField 
                value={datosBeneficiario?.direccion}
                onChange={(e) => {actualizaDatos('direccion', e.target.value)}}
                size='small'
                label="Dirección"
                type='text'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
               
            />
            <TextField 
                onChange={(e) => {actualizaDatos('colonia', e.target.value)}}

                size='small'
                label="Colonia"
                type='text'
                required
                value={datosBeneficiario?.colonia}
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
               
            />
            <TextField 
                onChange={(e) => {actualizaDatos('telefono', e.target.value)}}
                size='small'
                label="Teléfono"
                type='number'
                required
                value={datosBeneficiario?.telefono}
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
              
            />
                <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef} style={{visibility:'hidden'}} accept='image/*' type="file" capture="camera" />
                <Button
                    style={{
                    textTransform:'unset',
                    //maxWidth:140,
                    minWidth:'80%',
                    marginTop:0,
                    backgroundColor:'#B3282D'
                    }}
                    variant="contained"
                    onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                    >Frente Identificación <ImageSearch /></Button>
                <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef1} style={{visibility:'hidden'}} accept='image/*' type="file" capture="camera" />
                <Button
                    style={{
                    textTransform:'unset',
                    //maxWidth:140,
                    minWidth:'80%',
                    marginTop:0,
                    backgroundColor:'#B3282D'
                    }}
                    variant="contained"
                    onClick={() => uploadInputRef1.current && uploadInputRef1.current.click()}
                    >Reverso Identificación<ImageSearch /></Button>
                <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef2} style={{visibility:'hidden'}} accept='image/*' type="file" capture="camera" />
                <Button
                    style={{
                    textTransform:'unset',
                    //maxWidth:140,
                    minWidth:'40%',
                    marginTop:0,
                    backgroundColor:'#B3282D'
                    }}
                    variant="contained"
                    onClick={() => uploadInputRef2.current && uploadInputRef2.current.click()}
                    >Beneficiario <ImageSearch /></Button>

            <Button
                onClick={() => {setModalAgregaBeneficiario(false)}}
                variant='contained'
                style={{
                    textTransform:'unset',
                    color:'white',
                    backgroundColor:'#B3282D',
                    marginTop:10
                }}
            >
                Cancelar
            </Button>
            <Button
                type='submit'
                variant='contained'
                disabled={deshabilitado}
                style={{
                    textTransform:'unset',
                    color:deshabilitado ? 'gray':'white',
                    marginTop:10
                }}
            >
                Guardar
            </Button>            
        </div>      
        </form>  
  )
}
