import { CheckBox, KeyboardReturnRounded } from '@mui/icons-material';
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, Divider, FormControlLabel, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { UsuariosContext } from '../../context/UsuariosContext'
import { ObtenerDatos } from '../../helpers/ApiHelper';
import useDependencias from '../DependenciasComponent';

export const ModalUsuario = ({modalUsuario, setModalUsuario, usuarioSeleccionado}) => {
    const [datosUsuario, setDatosUsuario] = useState()
    const {dependencias, ComboDependencias, dependenciaSeleccionada, setDependenciaSeleccionada} = useDependencias();

    const handleClose = () => {
        setModalUsuario(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const data = new FormData();
        data.append('datos', JSON.stringify(datosUsuario))
        ObtenerDatos('/usuarios.php?funcion=guardarUsuario', data).then(response => {
            console.log('respuesta de guardar', response)
            setModalUsuario(false);
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Usuario guardado correctamente",
                showConfirmButton: false,
                timer: 1500
              });

        })
    }

    useEffect(() => {
        actualizaDatos('idDependencia', dependenciaSeleccionada?.value)
    }, [dependenciaSeleccionada])


    useEffect(() => {
        console.log('cambio seleccion de usuario en modal de edicion', usuarioSeleccionado)
        setDatosUsuario({
            userName:usuarioSeleccionado?.Usuarios_Username,
            password:usuarioSeleccionado?.Usuarios_Password,
            nombre:usuarioSeleccionado?.Usuarios_Nombre,
            apellidoPaterno:usuarioSeleccionado?.Usuarios_ApellidoPaterno,
            apellidoMaterno:usuarioSeleccionado?.Usuarios_ApellidoMaterno,
            telefono:usuarioSeleccionado?.telefono,
            autorizaApoyos:usuarioSeleccionado?.autorizaApoyos,
            idUsuario:usuarioSeleccionado?.Usuarios_IdUsuario
        }
        )
        if(usuarioSeleccionado?.Usuarios_IdUsuario) {
            setDependenciaSeleccionada({
                value:usuarioSeleccionado?.idDependencia,
                label:usuarioSeleccionado?.dependencia
            })
        }
    }, [usuarioSeleccionado])
    

    useEffect(() => {
        console.log('valor de usuario en modal', modalUsuario)
    }, [modalUsuario])
    
    const actualizaDatos = (campo, valor) => {
        setDatosUsuario({
            ...datosUsuario,
            [campo]:valor
        })
    }

    useEffect(() => {
        console.log('cambio datos de usuario', datosUsuario)
    }, [datosUsuario])

    const handleCheck = (e) => {
        console.log('valor de check', e);
        if(e.target.checked) {
            actualizaDatos('autorizaApoyos',1)
        } else {
            actualizaDatos('autorizaApoyos',0)

        }
    }

    if(usuarioSeleccionado?.Usuarios_IdUsuario) {
        
    }

    useEffect(() => {
        console.log('datos en edicion', datosUsuario)
    }, [datosUsuario])
    


  return (
    <Dialog

        maxWidth='sm'
        fullWidth
        open={modalUsuario}
        onClose={handleClose}
    >
        <form onSubmit={handleSubmit} >
            <DialogContent>

            <TextField
                fullWidth
                onChange={(e) => {actualizaDatos('userName', e.target.value)}}
                value={datosUsuario?.userName}

                size='small'
                label="Username"
                type='text'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
                
            />
            <ComboDependencias />
            <TextField
                fullWidth
                onChange={(e) => {actualizaDatos('password', e.target.value)}}
                value={datosUsuario?.password}

                size='small'
                label="Password"
                type='password'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
                
            />
            <TextField
                fullWidth
                onChange={(e) => {actualizaDatos('nombre', e.target.value)}}
                value={datosUsuario?.nombre}

                size='small'
                label="Nombre"
                type='text'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
                
            />
            <TextField
                fullWidth
                onChange={(e) => {actualizaDatos('apellidoPaterno', e.target.value)}}
                value={datosUsuario?.apellidoPaterno}

                size='small'
                label="Apellido Paterno"
                type='text'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
                
            />
            <TextField
                fullWidth
                onChange={(e) => {actualizaDatos('apellidoMaterno', e.target.value)}}
                value={datosUsuario?.apellidoMaterno}

                size='small'
                label="Apellido Materno"
                type='text'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
                
            />
            <TextField
                fullWidth
                onChange={(e) => {actualizaDatos('telefono', e.target.value)}}
                value={datosUsuario?.telefono}

                size='small'
                label="Teléfono"
                type='text'
                required={false}
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
                
            />
             <FormControlLabel control={<Checkbox onChange={(e) => {handleCheck(e)}} checked={datosUsuario?.autorizaApoyos==1 ? true:false} value={datosUsuario?.autorizaApoyos} />} label="Autoriza apoyos" />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button type="submit">Guardar</Button>
            </DialogActions>
        </form>
    </Dialog>
    
  )
}
