import React, { useContext } from 'react'
import { AuthContext } from '../context/AuthContext'
import { AgregarBeneficiario } from './AgregarBeneficiario'
import { AgregarApoyo } from './AgregarApoyo'

export const OpcionesMovil = () => {
    const {opcionMovil, setOpcionMovil} = useContext(AuthContext)
  return (
    <>
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            flex:1
        }}
    >
        <div
            onClick={() => {setOpcionMovil('agregar')}}
            style={{
                display:'flex',
                flexDirection:'column',
                flex:50,
                padding:5,
                margin:3,
                alignItems:'center',
                justifyContent:'center',
                backgroundColor:'#B3282D',
                borderRadius:10,
                color:'white'
            }}
        >
            Agregar Beneficiario
        </div>
        <div
            onClick={() => {setOpcionMovil('entregar')}}
            style={{
                display:'flex',
                flexDirection:'column',
                flex:50,
                padding:5,
                margin:3,
                alignItems:'center',
                justifyContent:'center',
                backgroundColor:'#B3282D',
                borderRadius:10,
                color:'white'
            }}
        >
            Entregar Apoyo
        </div>
    </div>
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            flex:1,
            backgroundColor:'transparent',
            justifyContent:'center'
        }}
    >
        {
            opcionMovil=='agregar' ? (
                <AgregarBeneficiario />
            ):(
                <AgregarApoyo />
            )
        }
    </div>
    </>
  )
}
