import { Cake, CameraAltRounded, Favorite, Female, Groups2, Groups3, HeartBroken, Home, Lock, Male, Person, Phone, Wc } from '@mui/icons-material'
import { Avatar, Button, Card, CardContent, CardHeader, CardMedia, Divider, IconButton, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { createRef, useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { UsuariosContext } from '../../context/UsuariosContext'
import { ObtenerDatos } from '../../helpers/ApiHelper'

export const CardUsuario = ({usuarioSeleccionado, usuarios, modalApoyo, setModalApoyo, apoyosOtorgados,setModalTablaApoyos, obtenerUsuarios}) => {
    const {modalPermisos, setModalPermisos} = useContext(AuthContext)
    //const {usuarioSeleccionado, usuarios, modalApoyo, setModalApoyo, apoyosOtorgados,setModalTablaApoyos, setModalPermisos} = useContext(UsuariosContext)
    console.log('usuario seleccionado recibido en card', usuarioSeleccionado)
    const {usuario} = useContext(AuthContext)
    const uploadInputRef = useRef();
    const imgUsuario = useRef();
    const [file, setFile] = useState();

    const SeleccionImagen = (event) => {
        if(event.target.files[0]) {
            setFile({file:event.target.files[0]});
            console.log('selecciono imagen',event);
            var reader = new FileReader();
            reader.onload = function(){
                imgUsuario.current.src = reader.result;
                subirFoto();
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const subirFoto = () => {
        console.log('foto a subir', uploadInputRef)
        const data = new FormData();
        data.append('idUsuario', usuarioSeleccionado?.Usuarios_IdUsuario)
        data.append('archivo', uploadInputRef.current.files[0])
        ObtenerDatos('/usuarios.php?funcion=subirFoto',data).then(response => {
            console.log('respuesta del servidor', response);
                usuarios?.map(usuario => {
                    if(usuario.idUsuario==usuarioSeleccionado?.idUsuario) {
                        console.log(uploadInputRef.current.files[0]);
                        usuario.foto=response;
                        usuarioSeleccionado.foto=response;
                    }
                })
        })
    }

    useEffect(() => {
        console.log('usuarioSeleccionado en card', usuarioSeleccionado)
    }, [usuarioSeleccionado])
    
    const handleImageError = () => {
        
    }
    

  return (
    <Card>
        <CardContent>
            <div
                style={{
                    position:'relative'
                }}
            >
                <div
                    style={{
                        position:'absolute',
                        bottom:20,
                        right:20
                    }}
                >
                    <IconButton
                        style={{backgroundColor:'gray', }}
                        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                    >
                        <CameraAltRounded style={{color:'black', opacity:1}} />
                    </IconButton>
                </div>
                <input ref={uploadInputRef} onChange={event => SeleccionImagen(event) }  style={{visibility:'hidden',width:1, height:1, position:'absolute'}} accept='.jpg' type="file" />                
                <img 
                    ref={imgUsuario}
                        style={{
                            minWidth:100,
                            width:'100%',
                            height:'auto'
                        }}
                        src={usuarioSeleccionado?.foto || 'https://mariolegarreta.ddns.net/deporti/imagenes/usuarios/sin.imagen.png'}
                        onError={() => imgUsuario.current.src='https://mariolegarreta.ddns.net/deporti/imagenes/usuarios/sin.imagen.png'}
                />
            </div>
                <Divider />
                {
                 (usuario.permisos).substr(1,1)=='1' ? (                
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    color:'black',
                    marginTop:20,
                    fontSize:13,
                    alignItems:'center'
                }}
            >
                <Lock style={{color:'black', marginRight:10, }} />  <Typography style={{cursor:'pointer'}} onClick={() => {console.log('click en permisos'); setModalPermisos(true)}}>Permisos</Typography>
            </div>
                 ):null
            }
            
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    color:'black',
                    marginTop:20,
                    fontSize:13,
                    alignItems:'center'
                }}
            >
                <Person style={{color:'black', marginRight:10, }} />  {usuarioSeleccionado?.Usuarios_Nombre+' '+usuarioSeleccionado?.Usuarios_ApellidoPaterno+' '+(usuarioSeleccionado?.Usuarios_ApellidoMaterno ? usuarioSeleccionado?.Usuarios_ApellidoMaterno:'')}
            </div>
            {/* <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    color:'black',
                    marginTop:10,
                    alignItems:'center',
                    fontSize:13
                }}
            >
                {usuarioSeleccionado?.idGenero==1 ? <Male style={{color:'blue', marginRight:10,}}/>:<Female style={{color:'pink', marginRight:10,}} />}    {usuarioSeleccionado?.genero}
            </div> */}
            {/* <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    color:'black',
                    marginTop:10,
                    alignItems:'center',
                    fontSize:13
                }}
            >
                <Cake style={{color:'chocolate', marginRight:10,}} />  {dayjs(usuarioSeleccionado?.fechaNacimiento).format('DD/MM/YYYY')}
            </div> */}
            {/* <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    color:'black',
                    marginTop:10,
                    alignItems:'center',
                    fontSize:13
                }}
            >
                <Favorite style={{color:'red', marginRight:10,}} />  {usuarioSeleccionado?.estadoCivil}
            </div> */}
            {/* <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    color:'black',
                    marginTop:10,
                    alignItems:'center',
                    fontSize:13
                }}
            >
                <Groups3 style={{color:'black', marginRight:10,}} />  {usuarioSeleccionado?.etiqueta}
            </div> */}
            {/* <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    color:'black',
                    marginTop:10,
                    alignItems:'center',
                    fontSize:13
                }}
            >
                <Home style={{color:'black', marginRight:10,}} /> {usuarioSeleccionado?.calle+' '+usuarioSeleccionado?.numeroExterior+(usuarioSeleccionado?.numeroInterior ? '-'+usuarioSeleccionado?.numeroInterior:'') }  
            </div>
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    color:'black',
                    marginTop:10,
                    alignItems:'center',
                    fontSize:13
                }}
            >
                <Phone style={{color:'black', marginRight:10,}} /> {usuarioSeleccionado?.telefono ? usuarioSeleccionado?.telefono:'No proporcionado' }
            </div> */}
            {/* {
                (usuario.permisos).substr(11,1)=='1' ? (
                  
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    color:'black',
                    marginTop:10,
                    alignItems:'center',
                    fontSize:13
                }}
            >
                <Avatar
                    onClick={() => {console.log('click en numero de apoyos'); setModalTablaApoyos(true)}}
                    size='small'
                    style={{
                        backgroundColor:'green',
                        width:30,
                        height:30,
                        marginRight:10
                        
                    }}
                >{apoyosOtorgados}</Avatar> Apoyos otorgados
            </div>
                ):null
            }
            {
                 (usuario.permisos).substr(10,1)=='1' ? (
                <Button
                    onClick={() => {setModalApoyo(true)}}
                    variant='outlined'
                    style={{
                        textTransform:'unset',
                        marginTop:10
                    }}
                >
                    Entrega de Apoyo
                </Button>
                
                ):null
            } */}
        </CardContent>
    </Card>

  )
}
