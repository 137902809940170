import { Button, Dialog, DialogActions, DialogContent, Divider, FormControlLabel, Switch } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { UsuariosContext } from '../../context/UsuariosContext'
import { AuthContext } from '../../context/AuthContext'

export const ModalPermisos = ({permisos, obtenerPermisos, usuarioSeleccionado}) => {
    const {modalPermisos, setModalPermisos, modalPassword, setModalPassword} = useContext(AuthContext)
    const handleClose = () => {
        setModalPermisos(false);
    }
    
    useEffect(() => {
        console.log('permisos en modal', permisos)
    }, [permisos])
    

    const handleChecked = (e, valor, posicion) => {
        console.log('cambio el valor', e.target.checked, valor, usuarioSeleccionado.Usuarios_IdUsuario);
        let permiso = 0;
        if(e.target.checked) {
            permiso=1;
        }
        const data = new FormData();
        data.append('idUsuario', usuarioSeleccionado.Usuarios_IdUsuario);
        data.append('permiso', permiso);
        data.append('posicion', posicion);
        fetch(process.env.REACT_APP_API_URL+'/usuarios.php?funcion=guardarPermiso', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(permisos => {
            obtenerPermisos();
        })
        .catch(error => {
          console.log('Ocurrio un error', error)
        })
    }

  return (
    <Dialog
        maxWidth='md'
        open={modalPermisos}
        onClose={handleClose}
        fullWidth
    >
        <DialogContent>
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'flex-end'
                }}
            >
                <Button
                    onClick={() => {setModalPassword(true)}}
                    variant='outlined'
                    style={{textTransform:'unset', marginBottom:20, color:'#B3282D', borderColor:'#B3282D'}}
                >Establecer contraseña</Button>
            </div>
            <Divider />

                   <div
            style={{
                display:'flex',
                flexDirection:'column',
                marginLeft:20,
                flex:40
            }}
            >
            {
                permisos?.map(grupo => (
                    <>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'row',
                            //flex:1,
                            marginLeft:20,
                            marginTop:20,
                            backgroundColor:'#B3282D',
                            color:'white',
                            padding:10,
                            borderTopLeftRadius:10,
                            borderTopRightRadius:10,
                            marginRight:10
                        }}
                        >
                        {grupo.descripcion}
                    </div>
                    <>
                    {
                        grupo?.permisosGrupo?.map(permiso => (
                            
                            <div
                            style={{
                                flexDirection:'row',
                                //flex:1,
                                marginLeft:20,
                                
                            }}
                            >
                                <FormControlLabel label={permiso.Descripcion} control={<Switch 
           sx={{
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: '#B3282D', // change the track color to green when "on"
            },
          }} style={{color:'#B3282D'}}
                                checked={permiso?.permisoUsuario=='1' ? true:false}  onChange={(e) => {handleChecked(e, permiso.permisoUsuario, permiso.Posicion)}}  />} />
                            </div>
                        ))
                    }
                    </>
                    </>
                ))
            }
        </div>
        </DialogContent>
        <Divider />
        <DialogActions>
            <Button style={{color:'black'}} onClick={() => {handleClose()}}>Cerrar</Button>
        </DialogActions>
    </Dialog>
  )
}
