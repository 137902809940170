import React, { useContext, useEffect, useRef, useState } from 'react'
import { ObtenerDatos } from '../helpers/ApiHelper'
import { AuthContext } from '../context/AuthContext'
import { Button, Checkbox, FormControlLabel, IconButton, TextField } from '@mui/material'
import useGeneros from '../components/GenerosComponent'
import { Rotate90DegreesCcwOutlined, RotateRightOutlined } from '@mui/icons-material'

export const VerificacionPage = () => {
  const {usuario} = useContext(AuthContext)
  const [datosBeneficiario, setDatosBeneficiario] = useState()
  const {generoSeleccionado, setGeneroSeleccionado, ComboGeneros} = useGeneros()
  const [rotacion, setRotacion] = useState(0)
  const imgAmpliada = useRef();
  const dvImagen = useRef();
  const [datosVerificacion, setDatosVerificacion] = useState({
    curp:0,
    telefono:0,
    foto:0,
    ine:0
  })
  const [datosValidos, setDatosValidos] = useState(false)

  const actualizaDatos = (campo, valor) => {
    setDatosBeneficiario({
      ...datosBeneficiario,
      [campo]:valor
    })
  }

  const obtenerPendientesVerificacion = () => {
    ObtenerDatos('/beneficiarios.php?funcion=pendientesVerificacion').then(response => {
      console.log('Beneficiarios pendientes de verificar', response)
    })
  }

  const procesarVerificacion = () => {
    let data = new FormData();
    data.append('idUsuario', usuario.Usuarios_IdUsuario)
    ObtenerDatos('/beneficiarios.php?funcion=procesarVerificacion', data).then(response => {
      console.log('respuesta procesar verificacion', response)
      setDatosBeneficiario({
        ...response
      })
      setGeneroSeleccionado({value:response?.idGenero, label:response?.genero})
    })
  }

  useEffect(() => {
    obtenerPendientesVerificacion();
  }, [])

  useEffect(() => {
    console.log(('datos beneficiario', datosBeneficiario))
  }, [datosBeneficiario])

  useEffect(() => {
    console.log(imgAmpliada.current?.getBoundingClientRect()?.height)
    dvImagen.current.height = imgAmpliada.current?.getBoundingClientRect()?.height
  }, [rotacion])
  
  
  const validaDatos = () => {
    if(datosVerificacion.curp==1 && datosVerificacion.telefono==1 && datosVerificacion.foto==1 && datosVerificacion.ine==1) {
      setDatosValidos(true)
    } else {
      setDatosValidos(false)
    }
  }

  const actualizaVerificacion = (campo, valor) => {
    setDatosVerificacion({
      ...datosVerificacion,
      [campo]:valor ? 1:0
    })
  }
  
  useEffect(() => {
    console.log('datos de verificacion', datosVerificacion)
    validaDatos()
  }, [datosVerificacion])
  
  const guardarVerificacion = () => {
    let data = new FormData();
    data.append('idUsuario', usuario.Usuarios_IdUsuario)
    data.append('datosVerificacion', JSON.stringify(datosVerificacion))
    data.append('datosBeneficiario', JSON.stringify(datosBeneficiario))
    ObtenerDatos('/beneficiarios.php?funcion=guardarVerificacion', data).then(response => {
      console.log('respuesta guardar verificacion', response)
      procesarVerificacion();
    })
  }

  

  return (
    <div
      style={{
        display:'flex',
        flexDirection:'row',
        backgroundColor:'transparent',
        flex:1,
        margin:20
      }}
    >
      <div
        style={{
          display:'flex',
          flexDirection:'column',
          flex:30,
          backgroundColor:'white',
          borderRadius:15,
          maxHeight:'95vh'
        }}
      >
        <div
          style={{
            display:'flex',
            flexDirection:'row',
            maxHeight:30,
            flex:1,
            padding:15
          }}

        >
          <Button
            onClick={() => {procesarVerificacion()}}
            variant='contained'
            style={{
              textTransform:'unset',
              backgroundColor:'#B3282D',
              borderRadius:20

            }}
          >
            Iniciar Verfificación
          </Button>
        </div>
        <div
          style={{
            display:'flex',
            flexDirection:'column',
            marginLeft:30,
            marginRight:50
          }}
        >
           <TextField 
                onChange={(e) => {actualizaDatos('nombre1', e.target.value)}}
                value={datosBeneficiario?.nombre1}
                size='small'
                label="Nombre 1"
                type='text'
                required
                InputLabelProps={{
                    shrink:true,
                }}
           />
            <TextField 
                onChange={(e) => {actualizaDatos('nombre2', e.target.value)}}
                value={datosBeneficiario?.nombre2}
                size='small'
                label="Nombre 2"
                type='text'
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
               
            />
            <TextField 
                onChange={(e) => {actualizaDatos('apellidoPaterno', e.target.value)}}
                value={datosBeneficiario?.apellidoPaterno}
                size='small'
                label="Apellido paterno"
                type='text'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
                
            />
            <TextField 
                onChange={(e) => {actualizaDatos('apellidoMaterno', e.target.value)}}
                value={datosBeneficiario?.apellidoMaterno}
                size='small'
                label="Apellido materno"
                type='text'
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
               
            />
            <div
                style={{
                    marginTop:5
                }}
            >
                <ComboGeneros />

            </div>
            <TextField 
                onChange={(e) => {actualizaDatos('fechaNacimiento', e.target.value)}}
                value={datosBeneficiario?.fechaNacimiento}

                size='small'
                label="Fecha de nacimiento"
                type='date'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
                
            />
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                flex:1
              }}
            >
            <TextField 
                onChange={(e) => {actualizaDatos('curp', e.target.value)}}
                value={datosBeneficiario?.curp}
                fullWidth
                size='small'
                label="CURP"
                type='text'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
                
            />
            <Checkbox onChange={(e) => {actualizaVerificacion('curp',e.target.checked)}} style={{color:'#B3282D'}} size='small' />
            </div>
            <TextField 
                value={datosBeneficiario?.direccion}
                onChange={(e) => {actualizaDatos('direccion', e.target.value)}}
                size='small'
                label="Dirección"
                type='text'
                required
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
               
            />
            <TextField 
                onChange={(e) => {actualizaDatos('colonia', e.target.value)}}

                size='small'
                label="Colonia"
                type='text'
                required
                value={datosBeneficiario?.colonia}
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
               
            />
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                flex:1
              }}
            >

            <TextField 
                onChange={(e) => {actualizaDatos('telefono', e.target.value)}}
                size='small'
                label="Teléfono"
                type='text'
                required
                fullWidth
                value={datosBeneficiario?.telefono}
                style={{
                    marginTop:5

                }}
                InputLabelProps={{
                    shrink:true,
                }}
              
            />
            <Checkbox  onChange={(e) => {actualizaVerificacion('telefono',e.target.checked)}} style={{color:'#B3282D'}} size='small' />

            </div>
            {/* <FormControlLabel control={<Checkbox size='small' />} label="CURP Correcta" />
            <FormControlLabel control={<Checkbox size='small' />} label="Identificación Correcta" /> */}
            {/* <FormControlLabel control={<Checkbox size='small' />} label="Fotografîa Correcta" /> */}
            {/* <FormControlLabel control={<Checkbox size='small' />} label="Teléfono Correcto" /> */}
            <Button  
              onClick={() => {guardarVerificacion()}}
              style={{textTransform:'unset', backgroundColor:datosValidos ? '#508747':'#B3282D', borderRadius:15, marginTop:10}} variant="contained" color="primary" >
              {datosValidos ? 'Siguiente, todo correcto':'Siguiente: registro con incidencias'}
            </Button>
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'column',
          flex:50,
          backgroundColor:'white',
          marginLeft:15,
          borderRadius:15,
          maxHeight:'95vh'
        }}
      >
        <div
          ref={dvImagen}
          style={{
            height:'50%',
    
            backgroundColor:'transparent',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            maxHeight:'60%',
            overflow:'auto'
          }}
        >
         <img 
              ref={imgAmpliada}
        //      width='auto'
        //      height='auto'
              style={{
                      //minWidth:100,
                      width:'50%',
                    //  maxWidth:'100%',
                      height:'auto',
                   //   maxHeight:500,
                 //     objectFit:'contain', 
                      transform: `rotate(${rotacion}deg)`,
                      positiom:'absolute',
                      left:0,
                      top:0,
                      justifyContent:'center'
                      //position:'relative'
                  }}
                  src={datosBeneficiario?.foto || 'https://mariolegarreta.ddns.net/deporti/imagenes/usuarios/sin.imagen.png'}
 //                 onError={() => imgUsuario.current.src='https://mariolegarreta.ddns.net/deporti/imagenes/usuarios/sin.imagen.png'}
          />
         </div>
         <div
          style={{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center'
          }}
         >
          <IconButton
            onClick={() => {setRotacion(rotacion+90)}}
            style={{color:'#B3282D',}}
          >
            <RotateRightOutlined />
          </IconButton>
          </div>
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              margin:20,
              justifyContent:'space-around'
            }}
          >
          <div
            style={{
              backgroundColor:'transparent',
              display:'flex',
              flexDirection:'column',
              justifyContent:'flex-end',
              alignItems:'flex-end',

            }}
          >
         <img 
//              ref={imgUsuario}
                onClick={() => {imgAmpliada.current.src=datosBeneficiario?.foto || 'https://mariolegarreta.ddns.net/deporti/imagenes/usuarios/sin.imagen.png'}}
                  style={{
                      minWidth:100,
                      width:100,
                      height:'auto',
                      objectFit:'contain'
                  }}
                  src={datosBeneficiario?.foto || 'https://mariolegarreta.ddns.net/deporti/imagenes/usuarios/sin.imagen.png'}
 //                 onError={() => imgUsuario.current.src='https://mariolegarreta.ddns.net/deporti/imagenes/usuarios/sin.imagen.png'}
          />
          <Checkbox  onChange={(e) => {actualizaVerificacion('foto',e.target.checked)}} style={{color:'#B3282D',}} size='small' />
        </div>
        <div
            style={{
              backgroundColor:'transparent',
              display:'flex',
              flexDirection:'column',
              justifyContent:'flex-end',
              alignItems:'flex-end',

            }}
          >
         <img 
//              ref={imgUsuario}
                  onClick={() => {imgAmpliada.current.src=datosBeneficiario?.identificacionAnverso || 'https://mariolegarreta.ddns.net/deporti/imagenes/usuarios/sin.imagen.png'}}

                  style={{
                      minWidth:100,
//                      maxHeight:'33%',
                      width:100,
                      height:'auto',
                      marginTop:20,
                      objectFit:'contain'
                  }}
                  src={datosBeneficiario?.identificacionAnverso || 'https://mariolegarreta.ddns.net/deporti/imagenes/usuarios/sin.imagen.png'}
 //                 onError={() => imgUsuario.current.src='https://mariolegarreta.ddns.net/deporti/imagenes/usuarios/sin.imagen.png'}
          />
            <Checkbox  onChange={(e) => {actualizaVerificacion('ine',e.target.checked)}} style={{color:'#B3282D'}} size='small' />

          </div>
         <img 
//              ref={imgUsuario}
                  onClick={() => {imgAmpliada.current.src=datosBeneficiario?.identificacionReverso || 'https://mariolegarreta.ddns.net/deporti/imagenes/usuarios/sin.imagen.png'}}

                  style={{
                      minWidth:100,
//                      maxHeight:'33%',
                      width:100,
                      height:'auto',
                      marginTop:20,
                      objectFit:'contain'
                  }}
                  src={datosBeneficiario?.identificacionReverso || 'https://mariolegarreta.ddns.net/deporti/imagenes/usuarios/sin.imagen.png'}
 //                 onError={() => imgUsuario.current.src='https://mariolegarreta.ddns.net/deporti/imagenes/usuarios/sin.imagen.png'}
          />

          </div>
      </div>
    </div>
  )
}
